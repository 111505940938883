/* eslint-disable react/no-danger */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
// import Head from 'next/head';
import parse from 'html-react-parser';
import Button from '@common_button';
import PriceFormat from '@common_priceformat';
import Banner from '@core_modules/product/pages/default/components/BannerSlider';
import Typography from '@common_typography';
import IconButton from '@material-ui/core/IconButton';
import Favorite from '@material-ui/icons/Favorite';
import ShareOutlined from '@material-ui/icons/ShareOutlined';
import classNames from 'classnames';
import React from 'react';
import { getHost } from '@helper_config';
import Breadcrumb from '@common_breadcrumb';
import dynamic from 'next/dynamic';
import useStyles from '@core_modules/product/pages/default/components/style';
import ExpandDetail from '@core_modules/product/pages/default/components/ExpandDetail';
import OverviewTabs from '@core_modules/product/pages/default/components/OverviewTabs';
import ListReviews from '@core_modules/product/pages/default/components/ListReviews';
import OptionItem from '@core_modules/product/pages/default/components/OptionItem';
import SharePopup from '@core_modules/product/pages/default/components/SharePopup';
import ModalPopupImage from '@core_modules/product/pages/default/components/ModalPopupImage';

const CountdownTimer = dynamic(() => import('@common_countdown'), { ssr: true });
const DesktopOptions = dynamic(() => import('@core_modules/product/pages/default/components/OptionItem/DesktopOptions'), { ssr: true });
const TabsView = dynamic(() => import('@core_modules/product/pages/default/components/DesktopTabs'), { ssr: true });
const RelatedProducts = dynamic(() => import('@core_modules/product/pages/default/components/RelatedProducts'), { ssr: true });
const UpsellProducts = dynamic(() => import('@core_modules/product/pages/default/components/UpsellProducts'), { ssr: true });
const WeltpixelLabel = dynamic(() => import('../../../../catalog/plugin/ProductItem/components/WeltpixelLabel'), { ssr: false });
const ColorSwatch = dynamic(() => import('@core_modules/product/pages/default/components/ColorSwatch'), { ssr: true });

const ProductPage = (props) => {
    const styles = useStyles();
    const {
        t,
        data,
        loadingRelatedProducts,
        dataRelatedProducts,
        loadingUpsellProducts,
        dataUpsellProducts,
        openOption,
        handleOption,
        setOpenOption,
        setBanner,
        setPrice,
        openShare,
        setOpenShare,
        route,
        banner,
        // openDrawer,
        // setOpenDrawer,
        breadcrumbsData,
        price,
        handleWishlist,
        // reviewValue,
        wishlist,
        overviewData,
        expandData,
        // relateData,
        openImageDetail,
        handleOpenImageDetail,
        // stockStatus,
        additionalPrice,
        enablePopupImage,
        // soldItem,
        categoryNow,
        weltpixel_labels,
        selectedColorSwatch,
        setSelectedColorSwatch,
        configurablePrice,
        salableQtyChildProducts,
    } = props;

    const eventProduct = categoryNow?.event;

    const favoritIcon = wishlist ? <Favorite className={styles.iconShare} /> : <Favorite className={styles.iconShare} />;

    let promotionContent = '';
    if (typeof window !== 'undefined' && data && data.promotion) {
        promotionContent = parse(data.promotion.replaceAll('&lt;', '<').replaceAll('&gt;', '>'));
    }

    return (
        <>
            {/* <Head>
                <script defer="defer" src="https://static.weaveroo.io/syndicationV3/build.js" />
            </Head> */}
            <div className="hidden-mobile">
                <ModalPopupImage
                    open={openImageDetail}
                    setOpen={handleOpenImageDetail}
                    banner={banner}
                />
            </div>
            <OptionItem
                {...props}
                open={openOption}
                setOpen={() => setOpenOption(!openOption)}
                banner={banner}
                setBanner={setBanner}
                setPrice={setPrice}
                selectedColorSwatch={selectedColorSwatch}
                setSelectedColorSwatch={setSelectedColorSwatch}
            />
            <SharePopup open={openShare} setOpen={() => setOpenShare(!openShare)} link={getHost() + route.asPath} {...props} />
            {eventProduct && <CountdownTimer targetDate={eventProduct.end_date} t={t} startDate={eventProduct.start_date} status={eventProduct.status} />}
            <div className={classNames(styles.container)}>
                <div className="hidden-mobile" style={{ padding: '14px 0 12px 0' }}>
                    <Breadcrumb data={breadcrumbsData} variant="text" />
                </div>
                <div className={classNames(styles.headContainer)}>
                    <div className={styles.productWrapper}>
                        <div className={styles.bannerColumn}>
                            <Banner
                                data={banner}
                                noLink
                                thumbnail
                                showArrow
                                contentWidth="auto"
                                autoPlay={false}
                                width={491}
                                height={491}
                                actionImage={enablePopupImage ? handleOpenImageDetail : () => { }}
                                customProduct={styles.bannerProduct}
                            >
                                <WeltpixelLabel
                                    t={t}
                                    weltpixel_labels={weltpixel_labels}
                                    categoryLabel={false}
                                    withThumbnailProduct
                                />
                            </Banner>
                        </div>
                        <div className={classNames(styles.priceColumn, 'hidden-mobile')}>
                            <div className={classNames(styles.titleContainer)}>
                                <div className={classNames(styles.titlePriceContainer, 'price-desktop')}>
                                    <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')} tdata="product-name">
                                        {data.name}
                                    </Typography>
                                    <div className={styles.priceStyle}>
                                        {
                                            // eslint-disable-next-line no-underscore-dangle
                                            data && data.__typename === 'ConfigurableProduct' ? (
                                                <PriceFormat
                                                    {...configurablePrice}
                                                    productType="ConfigurableProduct"
                                                    additionalPrice={additionalPrice}
                                                />
                                            ) : (
                                                <PriceFormat {...price} additionalPrice={additionalPrice} />
                                            )
                                        }
                                    </div>
                                    <div className={styles.overviewTabs}>
                                        <OverviewTabs dataInfo={overviewData} />
                                    </div>
                                    {
                                        promotionContent && (
                                            <div className={styles.promoStyle}>
                                                <Typography variant="span" letter="capitalize">
                                                    {t('product:promotion')}
                                                </Typography>
                                                <div className={styles.promoCard}>
                                                    {promotionContent}
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        data.vendor_name && (
                                            <div className={styles.vendorStyle}>
                                                <Typography variant="span" letter="capitalize">
                                                    {t('product:vendorName')}
                                                </Typography>
                                                <Typography variant="span" className={styles.vendorName} letter="capitalize">
                                                    {data.vendor_name}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        // eslint-disable-next-line no-underscore-dangle
                        data && data.__typename === 'ConfigurableProduct' && (
                            <div className={classNames(styles.colorSwatch, 'hidden-desktop')}>
                                <ColorSwatch
                                    sku={data.sku}
                                    url_key={data.url_key}
                                    selectedColorSwatch={selectedColorSwatch}
                                    setSelectedColorSwatch={setSelectedColorSwatch}
                                    pageType="pdp"
                                    salableQtyChildProducts={salableQtyChildProducts}
                                />
                            </div>
                        )
                    }
                    <div className={classNames(styles.qtyColumn)}>
                        <div className={classNames(styles.titleContainerQty, 'hidden-mobile')}>
                            <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                {t('product:adjustQty')}
                            </Typography>
                        </div>
                        <div className="hidden-desktop">
                            <div className={styles.titleContainer}>
                                <div className={styles.titlePriceContainer}>
                                    <Typography variant="title" type="bold" letter="capitalize" className={classNames(styles.title, 'clear-margin-padding')}>
                                        {data.name}
                                    </Typography>
                                    <div className={styles.priceStyle}>
                                        {
                                            // eslint-disable-next-line no-underscore-dangle
                                            data && data.__typename === 'ConfigurableProduct' ? (
                                                <PriceFormat
                                                    {...configurablePrice}
                                                    productType="ConfigurableProduct"
                                                    additionalPrice={additionalPrice}
                                                />
                                            ) : (
                                                <PriceFormat {...price} additionalPrice={additionalPrice} />
                                            )
                                        }
                                    </div>
                                    <div className={styles.overviewTabs}>
                                        <OverviewTabs dataInfo={overviewData} />
                                    </div>
                                    <div>
                                        {
                                            promotionContent && (
                                                <div className={styles.promoStyle}>
                                                    <Typography variant="span" letter="capitalize">
                                                        {t('product:promotion')}
                                                    </Typography>
                                                    <div className={styles.promoCard}>
                                                        {promotionContent}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            data.vendor_name && (
                                                <div className={styles.vendorStyle}>
                                                    <Typography variant="span" letter="capitalize">
                                                        {t('product:vendorName')}
                                                    </Typography>
                                                    <Typography variant="span" className={styles.vendorName} letter="capitalize">
                                                        {data.vendor_name}
                                                    </Typography>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className={styles.shareContainerQty}>
                                        <div>
                                            <IconButton className={styles.btnShare} onClick={handleWishlist}>
                                                {favoritIcon}
                                                {' '}
                                                <Typography variant="span" type="bold" color="primary">{t('product:wishlist')}</Typography>
                                            </IconButton>
                                        </div>
                                        <div>
                                            <Typography variant="span" type="bold" color="primary">|</Typography>
                                        </div>
                                        <div>
                                            <IconButton className={classNames(styles.btnShare)} onClick={() => setOpenShare(true)}>
                                                <ShareOutlined className={styles.iconShare} />
                                                {' '}
                                                <Typography variant="span" type="bold" color="primary">{t('product:share')}</Typography>
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ExpandDetail data={expandData} />
                            </div>
                            <ListReviews {...props} />
                        </div>
                        <div className="hidden-mobile">
                            <DesktopOptions {...props} setOpen={setOpenOption} setBanner={setBanner} setPrice={setPrice} />
                            <div className={styles.shareContainerQty}>
                                <div>
                                    <IconButton className={styles.btnShare} onClick={handleWishlist}>
                                        {favoritIcon}
                                        {' '}
                                        <Typography variant="span" type="bold" color="primary">{t('product:wishlist')}</Typography>
                                    </IconButton>
                                </div>
                                <div>
                                    <Typography variant="span" type="bold" color="primary">|</Typography>
                                </div>
                                <div>
                                    <IconButton className={classNames(styles.btnShare)} onClick={() => setOpenShare(true)}>
                                        <ShareOutlined className={styles.iconShare} />
                                        {' '}
                                        <Typography variant="span" type="bold" color="primary">{t('product:share')}</Typography>
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* {
                    typeof window !== 'undefined' && (
                        <weaver-tag
                            d="9to9_01"
                            p={data.sku}
                            l="en"
                            wt="wp"
                            mpn=""
                            ean=""
                            sku={data.sku}
                            br="9to9"
                            id="weaver"
                        />
                    )
                } */}

                <div className={classNames(styles.tabs, 'hidden-mobile')}>
                    <TabsView {...props} dataInfo={expandData} />
                </div>

                <RelatedProducts
                    t={t}
                    loadingRelatedProducts={loadingRelatedProducts}
                    dataRelatedProducts={dataRelatedProducts}
                />

                <UpsellProducts
                    t={t}
                    loadingUpsellProducts={loadingUpsellProducts}
                    dataUpsellProducts={dataUpsellProducts}
                />

                <div className={classNames(styles.footer, 'hidden-desktop')}>
                    <Button
                        className={styles.btnAddToCard}
                        color="primary"
                        onClick={handleOption}
                        disabled={data && data.stock_status === 'OUT_STOCK'}
                    >
                        <Typography variant="span" align="center" type="bold" letter="uppercase" color="white">
                            {t('product:addToCart')}
                        </Typography>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProductPage;
