import Skeleton from '@common_skeleton';
import ProductListSkeleton from '../../../plugin/ProductList/components/ProductListSkeleton';
import useStyles from './style';

const SkeletonCategory = () => {
    const styles = useStyles();

    return (
        <div className={styles.containerSkeleton}>
            <div className={styles.bannerSkeleton}>
                <Skeleton variant="text" width="30%" style={{ marginBottom: '8px' }} />
                <Skeleton
                    variant="rect"
                    xsStyle={{ width: '100%', height: '60vw' }}
                    mdStyle={{ width: '100%', height: '577px' }}
                    lgStyle={{ width: '100%', height: '577px' }}
                />
            </div>
            <div className={styles.productListSkeleton}>
                <ProductListSkeleton />
            </div>
        </div>
    );
};

export default SkeletonCategory;
