import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SECONDARY } from '@theme_color';
import { FlexRow, CreatePadding } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    containerProduct: {
        background: '#fff',
        margin: '0px 18px 0px 18px',
        [theme.breakpoints.down(768)]: {
            margin: '0px 0px 0px 0px',
        },
    },
    filterContainer: {
        ...FlexRow,
        alignItems: 'center',
        ...CreatePadding(18, 24, 18, 24),
    },
    countProductText: {
        justifyContent: 'flex-end',
        marginLeft: 55,
        fontSize: 13,
    },
    filterBtnContainer: {
        justifyContent: 'flex-start',
        ...FlexRow,
        alignItems: 'left',
        fontSize: 12,
    },
    btnFilter: {
        padding: 0,
        fontSize: 13,
    },
    iconFilter: {
        fontSize: 18,
        fontWeight: 'reguler',
    },
    tabs: {
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_SECONDARY}`,
    },
    productContainer: {
        overflow: 'hidden',
        ...CreatePadding(0, 0, 20, 0),
    },
    btnLoadmore: {
        cursor: 'pointer',
        width: '100%',
        padding: '20px',
        fontSize: '12px',
        background: '#fff',
        border: 'none',
        color: '#B4B4B4',
    },
    countProductTextDesktop: {
        justifyContent: 'flex-start',
        fontSize: 12,
        marginTop: -25,
    },
    divLoadMore: {
        width: '100%',
        textAlign: 'center',
        padding: 20,
    },
}));

export default useStyles;
