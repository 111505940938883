import React from 'react';
import Footer from '@core_modules/product/plugin/OptionItem/components/Footer';
// import Item from '@core_modules/product/plugin/OptionItem/ConfigurableOption/Item';

const ConfigurableView = (props) => {
    const {
        loading, disabled, showQty = true, handleAddToCart, qty, setQty, saleableQty,
        t, options, selectConfigurable, showAddToCart = true, salableQtyChildProducts, ...other
    } = props;

    return (
        <>
            {/* {options.map((item, index) => (
                <Item
                    key={index}
                    option={item.options}
                    selected={selectConfigurable}
                    value={item.value}
                    {...other}
                />
            ))} */}
            <Footer
                loading={loading}
                disabled={disabled}
                showQty={showQty}
                handleAddToCart={handleAddToCart}
                qty={qty}
                setQty={setQty}
                t={t}
                showAddToCart={showAddToCart}
                dataOptions={options}
                saleableQty={saleableQty}
                salableQtyChildProducts={salableQtyChildProducts}
                {...other}
            />
        </>
    );
};

export default ConfigurableView;
