import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, PRIMARY, GRAY_PRIMARY } from '@theme_color';
import {
    CreateMargin,
    CreatePadding,
    FlexRow,
    FlexColumn,
    showHide,
} from '@theme_mixins';
import { FONT_BIG } from '@theme_typography';

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        width: '100%',
        backgroundColor: WHITE,
        [theme.breakpoints.up('md')]: {
            width: '20%',
        },
    },
    appBar: {
        position: 'relative',
        backgroundColor: WHITE,
        ...CreatePadding(10, 12, 10, 18),
        boxShadow: 'none',
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
        height: '51px',
        ...FlexRow,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    btnClose: {
        padding: '0 !important',
    },
    iconClose: {
        ...FONT_BIG,
        color: PRIMARY,
    },
    title: {
        justifySelf: 'center',
        ...CreateMargin(16, 0, 16, 0),
    },
    body: {
        ...FlexColumn,
        position: 'relative',
        height: '100%',
    },
    textSearch: {
        ...FlexRow,
        justifyContent: 'space-between',
        ...CreatePadding(0, '15%', 0, 0),
    },
    rmMargin: {
        ...CreateMargin(0, 0, 0, 0),
    },
    result: {
        ...FlexColumn,
        ...CreateMargin(16, 0, 30, 0),
    },
    textValue: {
        ...FlexColumn,
        ...CreateMargin(10, 0, 10, 0),
    },
    ...showHide,
    displayFilterValue: {
        display: 'flex',
        flexWrap: 'nowrap',
        fontSize: '12px',
    },
    displayFilterValueTitle: {
        fontSize: '12px',
        textTransform: 'uppercase',
        marginBottom: '12px',
        fontWeight: 'bold',
    },
    displayFilterValueNum: {
        flex: '0 0 20px',
    },
    displayFilterValueVal: {
        flex: '1 1 auto',
        '& span': {
            textTransform: 'capitalize',
            fontWeight: 'bold',
        },
    },
    fieldContainer: {
        ...CreatePadding(10, 30, 10, 30),
        paddingBottom: 18,
        borderBottom: `1px solid ${GRAY_PRIMARY}`,
    },
    spanBottom: {
        width: '100%',
        minHeight: '120px',
    },
    last: {
        marginBottom: 70,
    },
    footer: {
        ...FlexRow,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        backgroundColor: WHITE,
        borderTop: `1px solid ${GRAY_PRIMARY}`,
        ...CreatePadding(20, 20, 20, 20),
        [theme.breakpoints.up('md')]: {
            width: '20%',
        },
    },
    btnSave: {
        display: 'block',
        margin: 'auto',
        width: 'calc(100% - 12px)',
        height: 41,
        backgroundColor: '#FF6A00',
        borderRadius: '8px !important',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        '&:hover': {
            background: '#FFF',
            color: '#000',
        },
    },
    btnClear: {
        display: 'block',
        margin: 'auto',
        width: 'calc(100% - 12px)',
        height: 41,
        color: '#FF6A00',
        borderColor: '#FF6A00',
        borderRadius: '8px !important',
        textTransform: 'capitalize',
        fontWeight: 'bold',
        '&:hover': {
            borderColor: '#FF6A00',
        },
    },
}));

export default useStyles;
