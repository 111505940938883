import IconButton from '@material-ui/core/IconButton';
import Typography from '@common_typography';
import Star from '@material-ui/icons/Star';
import { useTranslation } from '@i18n';
import useStyles from './style';

const RatingStar = ({
    value = 1,
    maxvalue = 1,
    onChange = () => { },
    disabled = true,
    sizeIcon = 14,
    sold = 1,
}) => {
    const styles = useStyles();
    const { t } = useTranslation(['catalog', 'common']);
    const icon = [];
    const styleIcon = {
        fontSize: sizeIcon,
    };
    for (let ind = 1; ind <= maxvalue; ind += 1) {
        icon.push(
            <IconButton
                className={styles.iconBtn}
                key={ind}
                disabled={disabled}
                onClick={() => onChange(ind)}
            >
                <Star style={styleIcon} className={styles.iconActive} />
                <Typography
                    variant="span"
                    letter="capitalize"
                    align="center"
                    className={styles.textStar}
                >
                    {value}
                    {sold > 0 && (
                        <>
&nbsp;|&nbsp;
                            {sold}
                            {t('catalog:sold')}
                        </>
                    )}
                </Typography>
            </IconButton>
            ,
        );
    }
    return (
        <div className={styles.container}>{icon.map((Item) => Item)}</div>
    );
};

export default RatingStar;
