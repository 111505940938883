import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE } from '@theme_color';
import { FlexColumn, CreatePadding } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        ...FlexColumn,
    },
    containerSkeleton: {
        width: '100%',
        height: '100%',
        padding: '18px 18px 0 18px',
        marginTop: '18px',
        [theme.breakpoints.down(768)]: {
            padding: '0 !important',
        },
    },
    bannerSkeleton: {
        [theme.breakpoints.down(768)]: {
            padding: '0 16px 0 16px',
        },
    },
    headContainer: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        ...CreatePadding(0, 18, 18, 18),
        [theme.breakpoints.down(768)]: {
            ...CreatePadding(0, 16, 16, 16),
            marginTop: '66px',
        },
    },
    cmsContainer: {
        padding: '0 18px 14px 18px',
        '& .pagebuilder-video-container iframe': {
            width: '100%',
            height: 'auto',
            minHeight: '480px',
            [theme.breakpoints.down('sm')]: {
                minHeight: '320px',
            },
        },
        [theme.breakpoints.down(768)]: {
            padding: '0 16px 14px 16px',
        },
    },
    onlyDescription: {
        paddingBottom: '28px !important',
        '& p': {
            margin: '0 !important',
        },
        [theme.breakpoints.down(768)]: {
            paddingBottom: '28px !important',
            '& p': {
                margin: '78px 0 0 0 !important',
            },
        },
    },
    productListSkeleton: {
        marginTop: '18px',
        paddingTop: '18px',
        background: '#fff',
    },
    headContainerNoBanner: {
        backgroundColor: WHITE,
        height: '40vh',
    },
    header: {
        left: '50%',
        right: '50%',
        top: '11px',
        position: 'absolute',
        borderBottom: 'none',
        fontWeight: 'bold',
    },
    breadcrumbs: {
        padding: '28px 18px 24px 18px',
        [theme.breakpoints.down(768)]: {
            padding: '16px 16px 0 16px',
        },
    },
    categoryName: {
        padding: '0px 16px 0',
        margin: 0,
    },
}));

export default useStyles;
