import Core from '@core_modules/product/plugin/OptionItem/SimpleProduct/core';
import View from '@core_modules/product/plugin/OptionItem/SimpleProduct/view';

const SimpleOptionItem = (props) => (
    <Core
        {...props}
        View={View}
    />
);

export default SimpleOptionItem;
