/* eslint-disable react/no-danger */
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import Paper from '@material-ui/core/Paper';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
import useStyles from '@core_modules/product/pages/default/components/OverviewTabs/style';
// import ListReviews from '@core_modules/product/pages/default/components/ListReviews';
// import { Typography } from '@root/node_modules/@material-ui/core/index';

function TabPanel(props) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            style={{ minHeight: 150, backgroundColor: '#FFFFFF', borderBottom: '1px solid #96999C' }}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const TabsView = (props) => {
    const { dataInfo } = props;
    const styles = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <AppBar position="static" color="default">
                <Tabs
                    className={styles.tabsStyle}
                    value={value}
                    onChange={handleChange}
                    // textColor="primary"
                    TabIndicatorProps={{ style: { background: '#FF6A00' } }}
                >
                    {dataInfo.map((val, idx) => <Tab label={val.title} key={idx} />)}
                </Tabs>
            </AppBar>
            {dataInfo.map((item, index) => (
                <TabPanel value={value} index={index} key={index} dir={theme.direction}>
                    {item.type === 'html'
                        ? (
                            <div className={styles.descriptionHtml}>
                                {item.content ? <span dangerouslySetInnerHTML={{ __html: item.content }} /> : null}
                            </div>
                        )
                        : item.type === 'array' && (
                            <>
                                {
                                    item.content.map((content, idx) => (

                                        <div className={styles.rowTab} key={idx}>
                                            <div className="tab-label">
                                                {content.label}
                                            </div>
                                            <div className="tab-value">
                                                :
                                                {' '}
                                                {content.value}
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )}
                    {item.sku
                        ? (
                            <div className={styles.rowTab}>
                                <div className="tab-label">
                                    SKU
                                </div>
                                <div className="tab-value">
                                    :
                                    {' '}
                                    {item.sku}
                                </div>
                            </div>
                        )
                        : null}
                </TabPanel>
            ))}
        </>
    );
};

export default TabsView;
