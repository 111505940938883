import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, RED } from '@theme_color';

export default makeStyles((theme) => ({
    btnAddToCard: {
        margin: '0 8px 0 0',
        width: '100%',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 'unset',
        backgroundColor: '#FF6A00',
        '&:hover': {
            background: '#FF6A00',
        },
    },
    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },
    error: {
        color: RED,
    },
    qty: {
        [theme.breakpoints.down('xs')]: {
            margin: '6px 0 0 0',
        },
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 15px 10px 0',
    },
    qtyWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: 20,
        },
    },
    subTotal: {
        marginTop: 20,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& p': {
            fontSize: 15,
            color: '#313133',
        },
        '& span': {
            fontSize: 24,
        },
    },
    optionWrap: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        marginTop: '20px',
    },
    maxBuy: {
        margin: '5px 0 !important',
    },
    closeBtnContainer: {
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        padding: '15px 20px 20px 20px',
        [theme.breakpoints.down(375)]: {
            padding: '15px 15px 20px 15px',
        },
    },
    closeBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    rowMobile: {
        height: '100%',
        overflowX: 'auto',
        paddingBottom: '12px',
    },
    unitPriceMobile: {
        fontSize: '18px',
    },
    bannerImageMobileContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '30px',
    },
    bannerImageMobile: {
        width: '173px',
        height: '173px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        overflow: 'hidden',
    },
    actionMobileContainer: {
        position: 'absolute',
        bottom: '0',
        left: '50%',
        transform: 'translateX(-50%)',
        minHeight: '50px',
        width: '100%',
        padding: '15px 20px 20px 20px',
        background: '#fff',
        [theme.breakpoints.down(375)]: {
            padding: '15px 15px 20px 15px',
        },
    },
    actionMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '24px',
    },
    actionLeftColumn: {
        flex: '1 1 100%',
        display: 'flex',
        flexDirection: 'column',
    },
    subTotalMobile: {
        fontSize: '12px',
    },
    finalPriceMobile: {
        marginTop: '6px !important',
        fontSize: '22px',
        lineHeight: '1.26',
    },
    actionRightColumn: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    salableMobile: {
        color: '#717073',
        fontSize: '12px',
    },
}));
