import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY } from '@theme_color';
import { Centering } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        [theme.breakpoints.down(768)]: {
            flexWrap: 'wrap',
        },
    },
    thumbnailContainer: {
        width: '83px',
        marginRight: '10px',
    },
    caraousel: {
        width: 'calc(100% - 93px)',
        maxWidth: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.down(768)]: {
            width: '100% !important',
        },
    },
    dots: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row',
        position: 'absolute',
        justifyContent: 'space-arround',
        bottom: 33,
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    dotsItem: {
        width: 7,
        height: 7,
        borderRadius: 100,
        backgroundColor: WHITE,
        margin: 5,
        cursor: 'pointer',
    },
    dotActive: {
        backgroundColor: PRIMARY,
        width: 10,
        height: 10,
    },
    hide: {
        display: 'none',
    },
    imageSlider: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up(767)]: {
            height: 'auto',
        },
    },
    thumborContainer: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        paddingTop: '116%',
        [theme.breakpoints.up(767)]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
        },
    },
    thumborImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        [theme.breakpoints.up(767)]: {
            height: 'auto',
            position: 'unset',
        },
    },

    arrow: {
        fontSize: '14px',
        background: '#96999C',
        position: 'absolute',
        ...Centering,
        padding: 10,
        borderRadius: '666px',
        textAlign: 'center',
        paddingLeft: 10,
        top: 'calc(50% - 1rem)',
        width: '30px',
        height: '30px',
        cursor: 'pointer',
        color: '#fff',
        '&:hover': {
            backgroundColor: PRIMARY,
            color: WHITE,
        },
    },
    leftArrow: {
        left: 20,
    },

    rightArrow: {
        right: 20,
    },
    leftArrowThumbnail: {

    },
    thumbnail: {
        border: '1px solid #dcdcdc',
        marginBottom: '3px',
        cursor: 'pointer',
    },
    thumbnailActive: {
        border: '1px solid #000',
        cursor: 'default',
    },
    thumbnailImg: {
        width: '80px !important',
        height: '80px !important',
    },
    customClass: {
        width: '100%',
        height: '100%;',
    },
    caraouselPdp: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    arrowGallery: {
        marginTop: '4px',
        fontSize: '20px',
        position: 'absolute',
        ...Centering,
        textAlign: 'center',
        // top: 'calc(50% - 1rem)',
        width: '100%',
        height: '20px',
        cursor: 'pointer',
        backgroundColor: '#96999C',
        color: WHITE,
        '&:hover': {
            backgroundColor: '#FF6A00',
            color: WHITE,
        },
    },
    galleryThumb: {
        '& .slick-vertical .slick-slide': {
            width: 'unset !important',
        },
        '& .slick-prev': {
            display: 'none !important',
        },
        '& .slick-list .slick-track': {
            height: 'unset !important',
        },
    },
}));

export default useStyles;
