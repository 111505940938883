/* eslint-disable max-len */
import Typography from '@common_typography';
import Button from '@common_button';
import useStyles from '../style';

const FilterView = (props) => {
    const {
        products, t, setOpenFilter, isAmoeba,
    } = props;
    const styles = useStyles();
    return (
        <div className={styles.filterContainer}>
            <div className={styles.filterBtnContainer}>
                <Button
                    variant="text"
                    customRootStyle={{ width: 'fit-content' }}
                    className={styles.btnFilter}
                    onClick={() => setOpenFilter(true)}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.996" viewBox="0 0 12 12.996">
                        <g id="filter" transform="translate(-10.118)">
                            <g id="Group_515" data-name="Group 515" transform="translate(10.118)">
                                <path id="Path_1470" data-name="Path 1470" d="M21.9,0H10.335a.222.222,0,0,0-.173.357L14.4,6.473a1.625,1.625,0,0,1,.3.941v5.337c.016.178.158.292.3.227l2.411-.941a.2.2,0,0,0,.126-.195V7.414a1.664,1.664,0,0,1,.3-.941L22.074.357A.222.222,0,0,0,21.9,0Z" transform="translate(-10.118)" />
                            </g>
                        </g>
                    </svg>
                    <Typography type="bold" variant="span" letter="uppercase">
                        {t('catalog:title:shortFilter')}
                    </Typography>
                </Button>
            </div>
            <Typography variant="p" type="regular" className={styles.countProductText}>
                {
                    isAmoeba ? products.total_product : products.total_count
                }
                {' '}
                {t('catalog:product:name')}
            </Typography>

        </div>
    );
};

export default FilterView;
