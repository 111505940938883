/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Typography from '@common_typography';
import ButtonQty from '@common_buttonqty';
import React from 'react';
import { formatPrice } from '@helper_currency';
import Button from '@common_button';
import classNames from 'classnames';
import Item from '@core_modules/product/plugin/OptionItem/ConfigurableOption/Item';
import Thumbor from '@common_slick/Banner/Thumbor';
import useStyles from './style';

const ConfigurableView = (props) => {
    const {
        loading, disabled, showQty = true, handleAddToCart, qty, setQty, saleableQty,
        t, showAddToCart = true, customStyleBtnAddToCard = '', labelAddToCart = '',
        maxQty = 10000, selectConfigurable, dataOptions, maxQtyAllowed, price, banner,
        setOpen, salableQtyChildProducts = [], ...other
    } = props;
    const styles = useStyles();
    const finalPrice = price.priceRange.minimum_price;

    return (
        <>
            <div className={classNames(styles.closeBtnContainer, 'hidden-desktop')}>
                <div className={styles.closeBtn} onClick={() => setOpen(false)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#9B9B9B" />
                    </svg>
                </div>
            </div>
            <div className={classNames(styles.rowMobile, 'hidden-desktop')}>
                {
                    banner && banner.length > 0 && (
                        <div className={styles.bannerImageMobileContainer}>
                            <div className={styles.bannerImageMobile}>
                                <Thumbor
                                    src={banner[0].imageUrl}
                                    alt="color swatch"
                                    width={173}
                                    height={173}
                                    quality={100}
                                    className={styles.thumbnailImg}
                                />
                            </div>
                        </div>
                    )
                }
                <Typography
                    variant="span"
                    className={classNames(styles.unitPriceMobile, 'clear-margin-padding', 'avenir-black')}
                >
                    {formatPrice(finalPrice.final_price.value)}
                </Typography>
                <div className={styles.optionWrap} tdata="configurable-option-index">
                    {dataOptions && (dataOptions.map((item, index) => (
                        <Item
                            key={index}
                            option={item.options}
                            selected={selectConfigurable}
                            value={item.value}
                            salableQtyChildProducts={salableQtyChildProducts}
                            {...other}
                        />
                    )))}
                </div>
            </div>
            <div className="hidden-mobile">
                {showQty && (
                    <div className={styles.qtyWrap}>
                        <div className={styles.qty}>
                            <ButtonQty
                                value={qty}
                                onChange={setQty}
                                max={maxQty}
                                disabled={disabled}
                            />
                            {
                                maxQtyAllowed && (
                                    <Typography type="bold" variant="span" className={styles.maxBuy}>
                                        {`${t('product:maxBuy')} ${maxQtyAllowed} pcs`}
                                    </Typography>
                                )
                            }

                        </div>
                        <Typography type="bold" variant="p" className="clear-margin-padding">
                            {`${t('product:stock')} ${saleableQty || 0}`}
                        </Typography>
                    </div>
                )}
                <div className={styles.subTotal}>
                    <div>
                        <Typography type="bold" variant="p" className="clear-margin-padding">
                            {t('product:subTotal')}
                        </Typography>
                    </div>
                    {
                        finalPrice && finalPrice.final_price && (
                            <div>
                                <Typography type="bold" variant="span" className="clear-margin-padding">
                                    {formatPrice(finalPrice.final_price.value * qty)}
                                </Typography>
                            </div>
                        )
                    }
                </div>
                <div className={styles.optionWrap} tdata="configurable-option-index">
                    {dataOptions && (dataOptions.map((item, index) => (
                        <Item
                            key={index}
                            option={item.options}
                            selected={selectConfigurable}
                            value={item.value}
                            salableQtyChildProducts={salableQtyChildProducts}
                            {...other}
                        />
                    )))}
                </div>
            </div>
            <div className={classNames(styles.actionMobileContainer, 'hidden-desktop')}>
                <div className={styles.actionMobile}>
                    <div className={styles.actionLeftColumn}>
                        <Typography
                            variant="span"
                            className={classNames(styles.subTotalMobile, 'clear-margin-padding', 'avenir-medium')}
                        >
                            {t('product:subTotal')}
                        </Typography>
                        {
                            finalPrice && finalPrice.final_price && (
                                <Typography
                                    variant="span"
                                    className={classNames(styles.finalPriceMobile, 'clear-margin-padding', 'avenir-black')}
                                >
                                    {formatPrice(finalPrice.final_price.value * qty)}
                                </Typography>
                            )
                        }
                    </div>
                    {showQty && (
                        <div className={styles.actionRightColumn}>
                            <Typography
                                variant="span"
                                align="right"
                                className={classNames(styles.salableMobile, 'clear-margin-padding', 'avenir-medium')}
                            >
                                {`${t('product:stock')} ${saleableQty || 0}`}
                            </Typography>
                            <div className={styles.qty}>
                                <ButtonQty
                                    value={qty}
                                    onChange={setQty}
                                    max={maxQty}
                                    disabled={disabled}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {
                    showAddToCart && (
                        <div className={styles.btnAddToCardContainer}>
                            <Button
                                className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                                onClick={handleAddToCart}
                                loading={loading}
                                disabled={disabled}
                                tdata="add-to-cart"
                            >
                                <Typography
                                    align="center"
                                    type="bold"
                                    letter="uppercase"
                                    color="white"
                                    variant="inherit"
                                >
                                    {labelAddToCart || t('product:addToCart')}
                                </Typography>
                            </Button>
                        </div>
                    )
                }
            </div>
            <div className="hidden-mobile">
                {
                    showAddToCart && (
                        <div className={styles.btnAddToCardContainer}>
                            <Button
                                className={classNames(styles.btnAddToCard, customStyleBtnAddToCard)}
                                onClick={handleAddToCart}
                                loading={loading}
                                disabled={disabled}
                                tdata="add-to-cart"
                            >
                                <Typography
                                    align="center"
                                    type="bold"
                                    letter="uppercase"
                                    color="white"
                                    variant="inherit"
                                >
                                    {labelAddToCart || t('product:addToCart')}
                                </Typography>
                            </Button>
                        </div>
                    )
                }
            </div>
        </>
    );
};

export default ConfigurableView;
