/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */
import React from 'react';
import { useRouter } from 'next/router';
import { formatPrice } from '@helper_currency';
import { sortByDataSearch, sortByDataCatalog } from '../FilterDesktop/sort';

const Filter = (props) => {
    const {
        FilterModalView, FilterView, filter, filterValue, isSearch, defaultSort, setFiltervalue, ...other
    } = props;
    const router = useRouter();
    const sortByData = isSearch ? sortByDataSearch : sortByDataCatalog;
    const [openFilter, setOpenFilter] = React.useState(false);
    const [selectedFilter, setFilter] = React.useState(filterValue);
    const [sort, setSort] = React.useState(filterValue.sort ? filterValue.sort : '');
    const [priceRange, setPriceRange] = React.useState(filterValue.priceRange ? filterValue.priceRange.split(',') : [0, 0]);
    const [priceRangeGroup, setPriceRangeGroup] = React.useState(priceRange.join(','));
    
    const priceRangeGroupData = [
        { label: '0 - 199,999', value: '0,199999' },
        { label: '200,000 - 399,999', value: '200000,399999' },
        { label: '400,000 - 599,999', value: '400000,599999' },
        { label: '600,000 - 799,999', value: '600000,799999' },
        { label: '≥ 800,000', value: '800000,999999999' },
    ];

    // set value price range group if range slider value was changed
    React.useEffect(() => {
        setPriceRangeGroup(priceRange.join(','));
    }, [priceRange]);

    // reset filter if route change
    React.useEffect(() => {
        setFilter(filterValue);
    }, [router.asPath]);

    const handleClear = () => {
        // reset value for sort component
        setSort(defaultSort || '');

        // reset value for price range component
        setPriceRange([0, 0]);

        // new filter with clear/reset value
        const newFilter = {
            q: selectedFilter.q,
            priceRange: [0, 0],
        };

        // delete params when empty value, ex: ...?q=undefined...
        Object.keys(newFilter).forEach((key) => {
            const emptyValues = [undefined, null, '', 'undefined', 'null'];
            if (emptyValues.includes(newFilter[key])) {
                delete newFilter[key];
            }
        });
        setOpenFilter(false);
        setFilter(newFilter);
        setFiltervalue({});
    };

    const handleSave = () => {
        if (selectedFilter.priceRange) {
            delete selectedFilter.priceRange;
        }

        if (selectedFilter.sort) {
            delete selectedFilter.sort;
        }
        const savedData = {
            selectedFilter,
        };
        if (sort !== '') {
            savedData.sort = sort;
        }
        if (priceRange[1] !== 0) {
            savedData.priceRange = priceRange;
        }
        setFiltervalue(savedData);
        setOpenFilter(!openFilter);
    };

    const setCheckedFilter = (name, value) => {
        let selected = '';
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < value.length; index++) {
            selected += `${index !== 0 ? ',' : ''}${value[index]}`;
        }
        selectedFilter[name] = selected;
        setFilter({ ...selectedFilter });
    };

    const setSelectedFilter = (code, value) => {
        selectedFilter[code] = value;
        setFilter({ ...selectedFilter });
    };
    
    // handle price range group 
    const handlePriceRangeGroup = (value) => {
        setPriceRangeGroup(value);
        setPriceRange(value.split(','));
    };

    const fieldNames = filter.map((item) => item.field);
    const selectedFilterOnly = Object.fromEntries(
        Object.entries(selectedFilter).filter(([key]) => fieldNames.includes(key)
            || ['sort', 'priceRange', 'discount_filter'].includes(key)),
    );

    // for displaying selected value
    const displaySelectedValue = [];
    if (selectedFilterOnly && typeof window !== 'undefined') {
        for (const keys in selectedFilterOnly) {
            if (keys == 'sort') {
                const tmpValue = JSON.parse(selectedFilter[keys]);
                displaySelectedValue.push({
                    label: `${keys} by ${tmpValue.key}`,
                    value: tmpValue.value,
                });
            } else if (keys == 'priceRange') {
                const tmpValue = selectedFilter[keys].includes(',') ? selectedFilter[keys].split(',') : [0, 0];
                displaySelectedValue.push({
                    label: 'price',
                    value: `${formatPrice(tmpValue[0])} - ${formatPrice(tmpValue[1])}`,
                });
            } else if (keys == 'discount_filter') {
                displaySelectedValue.push({
                    label: 'discount filter',
                    value: selectedFilter[keys],
                });
            } else {
                const label = keys.includes('_') ? keys.split('_').join(' ') : keys;
                displaySelectedValue.push({
                    label,
                    value: selectedFilter[keys],
                });
            }
        }
    }

    const ModalProps = {
        selectedFilter,
        setSelectedFilter,
        setCheckedFilter,
        handleSave,
        handleClear,
        sortByData,
        sort,
        setSort,
        priceRange,
        setPriceRange,
        priceRangeGroupData,
        priceRangeGroup,
        handlePriceRangeGroup,
        displaySelectedValue,
    };

    return (
        <>
            {FilterModalView ? (
                <FilterModalView
                    open={openFilter}
                    setOpen={() => setOpenFilter(!openFilter)}
                    {...props}
                    {...ModalProps}
                />
            ) : null}

            <FilterView
                openFilter={openFilter}
                setOpenFilter={setOpenFilter}
                isSearch={isSearch}
                {...ModalProps}
                {...other}
            />
        </>
    );
};

export default Filter;
