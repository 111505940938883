/* eslint-disable no-empty */
/* eslint-disable array-callback-return */
/* eslint-disable guard-for-in */
/* eslint-disable no-lonely-if */
/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import getQueryFromPath from '@helper_generatequery';
import TagManager from 'react-gtm-module';
import { modules } from '@config';
import { useBulkQuery } from '@core_modules/catalog/helpers/useBulkQuery';
import { bulkSkuMagentoQuery } from '@core_modules/catalog/services/graphql/productSchema';
import { useApolloClient } from '@apollo/client';
import { getLocalStorage, setLocalStorage } from '@helper_localstorage';
import {
    getProduct, getAggregationAmoeba, getProductAggregations, amoebaGetProductByCategory,
} from '../../services/graphql';
import * as Schema from '../../services/graphql/productSchema';
import getCategoryFromAgregations from '../../helpers/getCategory';
import generateConfig from '../../helpers/generateConfig';
import Content from './components';

const ProductPagination = (props) => {
    const {
        router,
        catId = 0, catalog_search_engine, customFilter, url_path, defaultSort, t,
        categoryPath, ErrorMessage, storeConfig, query, path, availableFilter, isLogin,
        isVirtualCategory = 0, disableAmoebaSearch = false, ...other
    } = props;

    /**
     * start handle previous
     * backPage = (from category)
     * isQueryChanged = (
     * 1. from search result
     * 2. from category with contain same slug
     */
    let backPage;
    let isQueryChanged;
    const pageInfo = getLocalStorage('page_info');
    if (pageInfo?.path === router.asPath) {
        backPage = pageInfo.page;
    } else if (
        !router.asPath.includes('catalogsearch/result')
        && (pageInfo?.path?.includes(router.asPath) || router.asPath.includes(pageInfo?.path))
    ) {
        isQueryChanged = true;
    }

    /**
     * handle page
     * specifically from search result not from category
     */
    if (pageInfo?.query?.q !== query?.q) {
        isQueryChanged = true;
    }
    // end handle previous

    const [products, setProducts] = React.useState({
        total_count: 0,
        items: [],
    });
    const [productsAmoeba, setProductsAmoeba] = React.useState({
        total_product: 0,
        products: [],
    });
    const [page, setPage] = React.useState(backPage || 1);
    const [pageSize, setPageSize] = React.useState(modules.catalog.productListing.pageSize || 10);
    const [totalCount, setTotalCount] = React.useState(0);
    const [totalPage, setTotalPage] = React.useState(0);
    const [loadmore, setLoadmore] = React.useState(false);
    const elastic = catalog_search_engine === 'elasticsuite';
    const isAmoeba = storeConfig && storeConfig.amoeba && isVirtualCategory !== 1 && !disableAmoebaSearch;
    let config = {
        customFilter: false,
        search: '',
        pageSize,
        currentPage: page,
        filter: [],
    };

    useEffect(() => {
        if (isQueryChanged) {
            setProducts({
                total_count: 0,
                items: [],
            });
            setProductsAmoeba({
                total_product: 0,
                products: [],
            });
            handleChangePage(1);
        }
    }, [isQueryChanged]);

    // set default sort when there is no sort in query
    if (defaultSort && !query.sort) {
        query.sort = JSON.stringify(defaultSort);
    }

    const setFiltervalue = (v) => {
        let queryParams = '';
        // eslint-disable-next-line array-callback-return
        Object.keys(v).map((key) => {
            if (key === 'selectedFilter') {
                // eslint-disable-next-line no-restricted-syntax
                for (const idx in v.selectedFilter) {
                    if (v.selectedFilter[idx] !== '' && !v[idx]) {
                        queryParams += `${queryParams !== '' ? '&' : ''}${idx}=${v.selectedFilter[idx]}`;
                    }
                }
            } else if (v[key] !== 0 && v[key] !== '') {
                queryParams += `${queryParams !== '' ? '&' : ''}${key}=${v[key]}`;
            }
        });
        Router.push(`/${url_path || '[...slug]'}`, encodeURI(`${path}${queryParams ? `?${queryParams}` : ''}`));
        setPage(1);
    };
    if (catId !== 0) {
        config.filter.push({
            type: 'category_id',
            value: catId,
        });
    }

    config = generateConfig(query, config, elastic, availableFilter, isAmoeba);
    const { loading, data, fetchMore } = isAmoeba
        ? getAggregationAmoeba(config, {
            variables: {
                pageSize,
                currentPage: page,
            },
        })
        : getProduct(config, {
            variables: {
                pageSize,
                currentPage: page,
            },
        });

    let amoebaProductLoading = false;
    let fetchMoreAmoeba = () => null;
    let amoebaData = null;

    if (isAmoeba) {
        const from = (page - 1) * pageSize;
        const { loading: amoebaProductLoadingIn, data: amoebaDataIn, fetchMore: fetchMoreAmoebaIn } = amoebaGetProductByCategory(config, {
            variables: {
                from,
                size: pageSize,
                isLogged: isLogin === 1,
            },
            fetchPolicy: 'network-only',
            context: {
                request: 'amoebaMiddleware',
            },
        });

        amoebaData = amoebaDataIn;
        amoebaProductLoading = amoebaProductLoadingIn;
        fetchMoreAmoeba = fetchMoreAmoebaIn;
    }

    /**
     * useEffect for total count & total page & loadmore to false
     *
     */
    useEffect(() => {
        const totalProductAmoeba = productsAmoeba && productsAmoeba.total_product ? productsAmoeba.total_product : 0;
        const totalProductMagento = products && products.total_count ? products.total_count : 0;

        if (isAmoeba) {
            const totalPageAmoeba = Math.ceil(totalProductAmoeba / pageSize);
            setTotalCount(totalProductAmoeba);
            setTotalPage(totalPageAmoeba);
        } else {
            const totalPageMagento = Math.ceil(totalProductMagento / pageSize);
            setTotalCount(totalProductMagento);
            setTotalPage(totalPageMagento);
        }
    }, [productsAmoeba, products]);

    /**
     * useEffect for pagination to change loadmore to false
     * after getting response from GQL API
     *
     */
    useEffect(() => {
        if (data?.products) {
            setProducts(data.products);
        }

        if (amoebaData?.categories?.length > 0) {
            setProductsAmoeba(amoebaData.categories[0]);
        }

        setLoadmore(false);
    }, [amoebaData, data]);

    /**
     * useEffect
     * set localstorage : page info
     * handle back from PDP
     */
    useEffect(() => {
        setLocalStorage('page_info', { path: router.asPath, page, query });
    }, [page]);

    let bulkData = [];

    if (typeof window !== 'undefined' && isAmoeba) {
        const productSku = amoebaData
            && amoebaData.categories
            && amoebaData.categories.length > 0
            ? amoebaData.categories[0].products.map((product) => product.sku)
            : [];
        const dataFromBulk = useBulkQuery(productSku);
        bulkData = dataFromBulk;
    }

    // generate filter if donthave custom filter
    const aggregations = [];
    if (!customFilter && !loading && products.aggregations) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < products.aggregations.length; index++) {
            aggregations.push({
                field: products.aggregations[index].attribute_code,
                label: products.aggregations[index].label,
                value: products.aggregations[index].options,
            });
        }
    }
    const category = getCategoryFromAgregations(aggregations);

    // eslint-disable-next-line no-shadow
    const renderEmptyMessage = (count, loading) => {
        if (count || loading) {
            return null;
        }
        return <ErrorMessage variant="warning" text={t('catalog:emptyProductSearchResult')} open />;
    };

    /**
     * function handleChangePage for pagination only
     * pagination is true
     * @param {*} pageInput
     */

    const handleChangePage = async (pageInput) => {
        try {
            if (isAmoeba) {
                if (fetchMoreAmoeba && typeof fetchMoreAmoeba !== 'undefined' && pageInput <= totalPage) {
                    setLoadmore(true);
                    const from = (pageInput - 1) * pageSize;
                    await fetchMoreAmoeba({
                        query: Schema.amoebaProductsByCategory({ ...config, from }),
                        variables: {
                            from,
                            size: pageSize,
                            isLogged: isLogin === 1,
                        },
                        fetchPolicy: 'network-only',
                        context: {
                            request: 'amoebaMiddleware',
                        },
                    });
                    setPage(pageInput);
                    // to change setLoadmore to false on useEffect
                }
            } else {
                if (fetchMore && typeof fetchMore !== 'undefined' && pageInput <= totalPage) {
                    setLoadmore(true);
                    await fetchMore({
                        query: Schema.getProduct({ ...config, currentPage: pageInput }),
                        variables: {
                            pageSize,
                            currentPage: pageInput,
                        },
                        fetchPolicy: 'network-only',
                    });
                    setPage(pageInput);
                    // to change setLoadmore to false on useEffect
                }
            }
        } catch (error) {
            setLoadmore(false);
        }
    };

    const dataDependancy = isAmoeba ? amoebaData : data;
    const dataCondition = isAmoeba
        ? amoebaData && amoebaData.categories && amoebaData.categories.length > 0
        : data && data.products;

    React.useEffect(() => {
        if (dataCondition) {
            if (isAmoeba) {
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'impression',
                        eventCategory: 'Ecommerce',
                        eventAction: 'Impression',
                        eventLabel: categoryPath ? `category ${categoryPath}` : '',
                        ecommerce: {
                            currencyCode: storeConfig && storeConfig.base_currency_code ? storeConfig.base_currency_code : 'IDR',
                            impressions: amoebaData.categories[0].products.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.category.length > 0 && product.category.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price.length > 0 ? product.price[0].original_price : 0,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };
                TagManager.dataLayer(tagManagerArgs);
            } else {
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'impression',
                        eventCategory: 'Ecommerce',
                        eventAction: 'Impression',
                        eventLabel: categoryPath ? `category ${categoryPath}` : '',
                        ecommerce: {
                            currencyCode: storeConfig && storeConfig.base_currency_code ? storeConfig.base_currency_code : 'IDR',
                            impressions: data.products.items.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.categories.length > 0 && product.categories.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price_range.minimum_price.regular_price.value,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };
                TagManager.dataLayer(tagManagerArgs);
            }
        }
    }, [dataDependancy, isAmoeba]);

    const contentProps = {
        availableFilter,
        loadmore,
        loading: isAmoeba ? amoebaProductLoading : loading,
        t,
        query,
        customFilter,
        elastic,
        aggregations,
        setFiltervalue,
        category,
        defaultSort,
        config,
        products: isAmoeba ? productsAmoeba : products,
        categoryPath,
        renderEmptyMessage,
        storeConfig,
        isAmoeba,
        bulkData,
        page,
        totalPage,
        totalCount,
        setPageSize,
        handleChangePage,
    };

    return (
        <Content
            {...contentProps}
            {...other}
        />
    );
};

const ProductLoadMore = (props) => {
    const {
        router,
        catId = 0, catalog_search_engine, customFilter, url_path, defaultSort, t,
        categoryPath, ErrorMessage, storeConfig, query, path, availableFilter, isLogin,
        isVirtualCategory = 0, disableAmoebaSearch = false, ...other
    } = props;

    const client = useApolloClient();

    const [products, setProducts] = React.useState({
        total_count: 0,
        items: [],
    });
    const [productsAmoeba, setProductsAmoeba] = React.useState({
        total_product: 0,
        products: [],
    });
    const [bulkData, setBulkData] = React.useState({
        fetchedSku: [],
        products: [],
    });
    const [urlPage, setUrlPage] = React.useState(router.asPath);
    const [loadmore, setLoadmore] = React.useState(false);
    const elastic = catalog_search_engine === 'elasticsuite';
    const isAmoeba = storeConfig && storeConfig.amoeba && isVirtualCategory !== 1 && !disableAmoebaSearch;
    const pageSize = modules.catalog.productListing.pageSize || 10;
    let config = {
        customFilter: false,
        search: '',
        pageSize,
        currentPage: 1,
        filter: [],
    };

    // set default sort when there is no sort in query
    // if (defaultSort && !query.sort) {
    //     query.sort = JSON.stringify(defaultSort);
    // }

    const setFiltervalue = (v) => {
        let queryParams = '';
        // eslint-disable-next-line array-callback-return
        Object.keys(v).map((key) => {
            if (key === 'selectedFilter') {
                // eslint-disable-next-line no-restricted-syntax
                for (const idx in v.selectedFilter) {
                    if (v.selectedFilter[idx] !== '' && !v[idx]) {
                        queryParams += `${queryParams !== '' ? '&' : ''}${idx}=${v.selectedFilter[idx]}`;
                    }
                }
            } else if (v[key] !== 0 && v[key] !== '') {
                queryParams += `${queryParams !== '' ? '&' : ''}${key}=${v[key]}`;
            }
        });
        Router.push(`/${url_path || '[...slug]'}`, encodeURI(`${path}${queryParams ? `?${queryParams}` : ''}`));
    };
    if (catId !== 0) {
        config.filter.push({
            type: 'category_id',
            value: catId,
        });
    }

    config = generateConfig(query, config, elastic, availableFilter, isAmoeba);
    const { loading, data, fetchMore } = isAmoeba
        ? getAggregationAmoeba(config, {
            variables: {
                pageSize,
                currentPage: 1,
            },
        })
        : getProduct(config, {
            variables: {
                pageSize,
                currentPage: 1,
            },
        });
    let amoebaProductLoading = false;
    let fetchMoreAmoeba = () => null;
    let amoebaData = null;
    let isChangedAmoebaSetting = false;

    if (isAmoeba) {
        const { loading: amoebaProductLoadingIn, data: amoebaDataIn, fetchMore: fetchMoreAmoebaIn } = amoebaGetProductByCategory(config, {
            variables: {
                from: 0,
                size: pageSize,
                isLogged: isLogin === 1,
            },
            context: {
                request: 'amoebaMiddleware',
            },
        });

        amoebaData = amoebaDataIn;
        amoebaProductLoading = amoebaProductLoadingIn;
        fetchMoreAmoeba = fetchMoreAmoebaIn;
        isChangedAmoebaSetting = true;
    }

    // generate filter if donthave custom filter
    const aggregations = [];
    if (!customFilter && !loading && products.aggregations) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < products.aggregations.length; index++) {
            aggregations.push({
                field: products.aggregations[index].attribute_code,
                label: products.aggregations[index].label,
                value: products.aggregations[index].options,
            });
        }
    }
    const category = getCategoryFromAgregations(aggregations);

    // eslint-disable-next-line no-shadow
    const renderEmptyMessage = (count, loading) => {
        if (count || loading) {
            return null;
        }
        return <ErrorMessage variant="warning" text={t('catalog:emptyProductSearchResult')} open />;
    };

    const handleLoadMore = async () => {
        try {
            if (isAmoeba) {
                const totalAllAmoeba = productsAmoeba.total_product;
                const totalCurrentAmoeba = productsAmoeba.products.length;
                // pageTemp without + 1 because use (from) in amoeba not (page)
                const pageTemp = Math.ceil(amoebaData.categories[0].products.length / pageSize);

                if (fetchMoreAmoeba && typeof fetchMoreAmoeba !== 'undefined' && totalCurrentAmoeba < totalAllAmoeba) {
                    setLoadmore(true);
                    await fetchMoreAmoeba({
                        query: Schema.amoebaProductsByCategory({ ...config, from: pageTemp * pageSize }),
                        variables: {
                            from: pageTemp * pageSize,
                            size: pageSize,
                            isLogged: isLogin === 1,
                        },
                        context: {
                            request: 'amoebaMiddleware',
                        },
                        updateQuery: (
                            previousResult,
                            { fetchMoreResult },
                        ) => {
                            const categoriesResult = [];

                            /**
                             * Checking double hit API with same page
                             * prevent duplicate data
                             */
                            if (pageTemp + 1
                                === Math.ceil((previousResult.categories[0].products.length + fetchMoreResult.categories[0].products.length)
                                    / pageSize)
                            ) {
                                categoriesResult.push({
                                    ...fetchMoreResult.categories[0],
                                    products: [
                                        ...previousResult.categories[0].products,
                                        ...fetchMoreResult.categories[0].products,
                                    ],
                                });
                            } else {
                                categoriesResult.push({
                                    ...previousResult.categories[0],
                                });
                            }

                            setLoadmore(false);
                            return {
                                categories: [
                                    ...categoriesResult,
                                ],
                            };
                        },
                    });
                }
            } else {
                const totalAllMagento = products.total_count;
                const totalCurrentMagento = products.items.length;
                // pageTemp with + 1 because use (page) param in magento
                const pageTemp = Math.ceil(data.products.items.length / pageSize + 1);

                if (fetchMore && typeof fetchMore !== 'undefined' && totalCurrentMagento < totalAllMagento) {
                    setLoadmore(true);
                    fetchMore({
                        query: Schema.getProduct({ ...config, currentPage: pageTemp }),
                        variables: {
                            pageSize,
                            currentPage: pageTemp,
                        },
                        updateQuery: (
                            previousResult,
                            { fetchMoreResult },
                        ) => {
                            let finalProduct = {};
                            /**
                             * Checking double hit API with same page
                             * prevent duplicate data
                             */

                            if (pageTemp === Math.ceil((previousResult.products.items.length + fetchMoreResult.products.items.length) / pageSize)) {
                                finalProduct = {
                                    ...fetchMoreResult.products,
                                    items: [
                                        ...previousResult.products.items,
                                        ...fetchMoreResult.products.items,
                                    ],
                                };
                            } else {
                                finalProduct = {
                                    ...previousResult.products,
                                };
                            }

                            setLoadmore(false);
                            return {
                                products: finalProduct,
                            };
                        },
                    });
                }
            }
        } catch (error) { }
    };

    const dataDependancy = isAmoeba ? amoebaData : data;
    const dataCondition = isAmoeba
        ? amoebaData && amoebaData.categories && amoebaData.categories.length > 0
        : data && data.products;

    React.useEffect(() => {
        if (dataCondition) {
            if (isAmoeba) {
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'impression',
                        eventCategory: 'Ecommerce',
                        eventAction: 'Impression',
                        eventLabel: categoryPath ? `category ${categoryPath}` : '',
                        ecommerce: {
                            currencyCode: storeConfig && storeConfig.base_currency_code ? storeConfig.base_currency_code : 'IDR',
                            impressions: amoebaData.categories[0].products.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.category.length > 0 && product.category.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price.length > 0 ? product.price[0].original_price : 0,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };

                // GA 4 dataLayer
                const tagManagerArgsGA4 = {
                    dataLayer: {
                        event: 'view_item_list',
                        pageName: categoryPath ? `category ${categoryPath}` : '',
                        pageType: 'category',
                        ecommerce: {
                            items: amoebaData.categories[0].products.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.category.length > 0 && product.category.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price.length > 0 ? product.price[0].original_price : 0,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };
                TagManager.dataLayer(tagManagerArgs);
                TagManager.dataLayer(tagManagerArgsGA4);
            } else {
                const tagManagerArgs = {
                    dataLayer: {
                        event: 'impression',
                        eventCategory: 'Ecommerce',
                        eventAction: 'Impression',
                        eventLabel: categoryPath ? `category ${categoryPath}` : '',
                        ecommerce: {
                            currencyCode: storeConfig && storeConfig.base_currency_code ? storeConfig.base_currency_code : 'IDR',
                            impressions: data.products.items.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.categories.length > 0 && product.categories.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price_range.minimum_price.regular_price.value,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };
                // GA 4 dataLayer
                const tagManagerArgsGA4 = {
                    dataLayer: {
                        event: 'view_item_list',
                        pageName: categoryPath ? `category ${categoryPath}` : '',
                        pageType: 'category',
                        ecommerce: {
                            items: data.products.items.map((product, index) => {
                                let categoryProduct = '';
                                // eslint-disable-next-line no-unused-expressions
                                product.categories.length > 0 && product.categories.map(({ name }, indx) => {
                                    if (indx > 0) categoryProduct += `/${name}`;
                                    else categoryProduct += name;
                                });
                                return {
                                    name: product.name,
                                    id: product.sku,
                                    category: categoryProduct,
                                    price: product.price_range.minimum_price.regular_price.value,
                                    list: categoryProduct,
                                    position: index,
                                };
                            }),
                        },
                    },
                };

                TagManager.dataLayer(tagManagerArgs);
                TagManager.dataLayer(tagManagerArgsGA4);
            }
        }
    }, [dataDependancy, isAmoeba]);

    /**
     * useEffect for set data
     * after getting response from GQL API
     *
     */

    async function fetchBulkData(allProducts) {
        const filteredSku = allProducts
            .filter((product) => !bulkData.fetchedSku.find((fetchedSku) => fetchedSku === product?.sku))
            .map((product) => product.sku);

        setBulkData((prevBulkData) => ({
            ...prevBulkData,
            fetchedSku: [...prevBulkData.fetchedSku, ...filteredSku],
        }));

        try {
            const { data: dataBulk } = await client.query({
                query: bulkSkuMagentoQuery,
                variables: {
                    bulkSku: filteredSku,
                },
            });

            const newBulkData = dataBulk.customProductSearch;
            setBulkData((prevBulkData) => ({
                ...prevBulkData,
                products: [...prevBulkData.products, ...newBulkData],
            }));
        } catch (e) {
            // console.log(e);
        }
    }

    useEffect(() => {
        if (data?.products) {
            setProducts(data.products);
        }

        if (amoebaData?.categories?.length > 0) {
            setProductsAmoeba(amoebaData.categories[0]);
            fetchBulkData(amoebaData.categories[0].products);
        }
    }, [amoebaData, data]);

    /**
     * useEffect for filter issue
     * when user bolak balik filter di induk url yang sama
     */
    useEffect(() => {
        if (urlPage !== router.asPath) {
            setUrlPage(router.asPath);
            /**
             * @var isChangedAmoebaSetting
             * used for waiting initial fetchMoreAmoeba
             */

            if (isAmoeba && isChangedAmoebaSetting) {
                fetchMoreAmoeba({
                    query: Schema.amoebaProductsByCategory({ ...config, from: 0 }),
                    variables: {
                        from: 0,
                        size: pageSize,
                        isLogged: isLogin === 1,
                    },
                    context: {
                        request: 'amoebaMiddleware',
                    },
                });
            }

            if (!isAmoeba) {
                fetchMore({
                    query: Schema.getProduct({ ...config, currentPage: 1 }),
                    variables: {
                        pageSize,
                        currentPage: 1,
                    },
                });
            }
        }
    }, [router.asPath, isChangedAmoebaSetting]);

    const contentProps = {
        availableFilter,
        loadmore,
        loading: isAmoeba ? amoebaProductLoading : loading,
        t,
        query,
        customFilter,
        elastic,
        aggregations,
        setFiltervalue,
        category,
        defaultSort,
        config,
        products: isAmoeba ? productsAmoeba : products,
        categoryPath,
        handleLoadMore,
        renderEmptyMessage,
        storeConfig,
        isAmoeba,
        bulkData: bulkData.products,
    };

    return (
        <Content
            {...contentProps}
            {...other}
        />
    );
};

const ProductWrapper = (props) => {
    const router = useRouter();
    const { path, query } = getQueryFromPath(router);
    const { storeConfig, catId = 0 } = props;
    /**
     * config from BO
     * pagination or loadmore
     * weltpixel_infinite_scroll_ajax_catalog_config
     * 0 = pagination
     * 1 = loadmore/infinity scroll
     */
    const isPagination = storeConfig.weltpixel_infinite_scroll_ajax_catalog_config !== '1';
    const Product = isPagination ? ProductPagination : ProductLoadMore;

    let availableFilter = [];
    let loadingAgg;
    if (Object.keys(query).length > 0) {
        let aggrProps = {};
        if (catId && catId !== 0) {
            aggrProps = {
                ...aggrProps,
                variables: {
                    filter: { category_id: { eq: String(catId) } },
                },
            };
        }
        const { data: dataAgg, loading } = getProductAggregations(aggrProps);
        loadingAgg = loading;
        availableFilter = dataAgg?.products?.aggregations ?? [];
    }
    if (loadingAgg) {
        return <span />;
    }
    return (
        <Product
            {...props}
            availableFilter={availableFilter}
            path={path}
            query={query}
            router={router}
            isPagination={isPagination}
        />
    );
};

export default ProductWrapper;
