import makeStyles from '@material-ui/core/styles/makeStyles';
import { WHITE, RED } from '@theme_color';

export default makeStyles((theme) => ({
    popUpWrapper: {
        borderRadius: '20px 20px 0 0',
    },
    root: {
        position: 'relative',
        zIndex: 3,
        backgroundColor: WHITE,
        width: '100%',
        height: '80vh',
        padding: '15px 20px 160px 20px',
        [theme.breakpoints.down(375)]: {
            padding: '15px 15px 160px 15px',
        },
    },
    bannerContainer: {
        backgroundPosition: 'center',
        background: 'transparent',
        height: '100vh',
    },
    img: {
        display: 'block',
        width: 'auto',
    },
    btnClose: {
        margin: '0 0 15px 0',
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
        },
    },
    sizeContainer: {
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            margin: '20px 0 10px 0',
        },
    },
    classContainer: {
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    center: {
        justifyContent: 'center',
    },
    btnAddToCard: {
        [theme.breakpoints.down('sm')]: {
            left: '50%',
            '-webkit-transform': 'translateX(-50%)',
            transform: ' translateX(-50%)',
        },
        [theme.breakpoints.up('sm')]: {
            width: 316,
            float: 'left',
        },
        margin: '0 8px 0 0',
        width: '100%',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 100,
    },
    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },
    error: {
        color: RED,
    },
    qty: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 15px 30px 15px',
            alignItems: 'center',
        },
        margin: '0 15px 30px 0',
        display: 'flex',
        flexDirection: 'column',
    },
    select: {
        minWidth: '30%',
        width: 'auto',
        maxWidth: '100%',
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '75%',
        },
        '& .label-select': {
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                textAlign: 'left',
            },
        },
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& .label-select': {
        },
        '& .label-select-value': {
            marginLeft: 20,
            fontSize: 14,
        },
        justifyContent: 'flex-between',
        alignItems: 'center',
    },
    stylesItemOption: {
        width: 30,
        height: 30,
        margin: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customizeContainer: {
        width: '100%',
        height: 100,
        backgroundColor: 'red',
        visibility: 'hidden',
        transition: 'top 1s ease',
        opacity: '0',
    },
    customizeContainerOpen: {
        visibility: 'visible',
        transition: 'top 1s ease',
        opacity: '1',
    },
    loadingCart: {
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
