import { makeStyles } from '@material-ui/core/styles';
import { WHITE, PRIMARY, GRAY_SECONDARY } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    caraousel: {
        width: '100%',
        height: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            width: 'calc(100% - 150px)',
        },
    },
    dots: {
        zIndex: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '42px',
        [theme.breakpoints.up('sm')]: {
            marginTop: 'unset !important',
            position: 'absolute',
            justifyContent: 'space-arround',
            bottom: 33,
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    dotsItem: {
        width: 10,
        height: 10,
        borderRadius: 100,
        backgroundColor: '#F1EDED',
        margin: 5,
        cursor: 'pointer',
    },
    dotActive: {
        backgroundColor: '#FF6A00',
    },
    hide: {
        display: 'none',
    },
    imageSlider: {
        display: 'flex',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
        },
    },
    thumborContainer: {
        backgroundColor: '#eee',
        width: '100%',
        position: 'relative',
        paddingTop: '116%',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            paddingTop: 0,
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: WHITE,
        },
    },
    thumborImage: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        [theme.breakpoints.up('sm')]: {
            height: 'auto',
            position: 'unset',
        },
    },
    arrow: {
        fontSize: '14px',
        backgroundColor: '#96999C',
        position: 'absolute',
        borderRadius: 666,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 30,
        height: 30,
        cursor: 'pointer',
        transition: '0.25s ease-in-out',
        color: WHITE,
        '&:hover': {
            backgroundColor: PRIMARY,
        },
    },
    arrowIcon: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    leftArrow: {
        left: 18,
    },

    rightArrow: {
        right: 18,
    },
    thumbnail: {
        border: '1px solid #dcdcdc',
        marginBottom: '10px',
        borderRadius: '3px',
        padding: '5px',
        cursor: 'pointer',
    },
    thumbnailActive: {
        border: '1px solid #6b6868',
        cursor: 'default',
    },
    thumbnailImg: {
        width: '100px !important',
        height: '100px !important',
    },
    customClass: {
        width: '99%',
        height: 'auto',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            height: 'calc(100vh - 100px) !important',
        },
    },
    actionZoom: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '0',
        top: '0',
        zIndex: theme.zIndex.drawer + 9,
    },
    buttonActionZoom: {
        margin: 10,
        color: GRAY_SECONDARY,
        fontSize: '3rem',
    },
}));

export default useStyles;
