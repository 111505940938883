import makeStyles from '@material-ui/core/styles/makeStyles';
import { CreateBorder, CreateMargin } from '@theme_mixins';
import { PRIMARY } from '@theme_color';
import { FONT_DEFAULT, FONT_10, FONT_REGULAR } from '@theme_typography';

export default makeStyles((theme) => ({
    tabsStyle: {
        '& .MuiTab-wrapper': {
            fontWeight: 'bold',
            textTransform: 'capitalize',
        },
        '& .Mui-selected .MuiTab-wrapper': {
            color: '#FF6A00',
        },
        '& .MuiTab-root': {
            width: 'auto',
            minWidth: 80,
            [theme.breakpoints.down(430)]: {
                fontSize: '0.675rem',
            },
        },
    },
    root: {
        width: '100%',
        ...CreateMargin(15, 0, 15, 0),
        ...CreateBorder('1px', 0, 0, 0, PRIMARY),
    },
    descriptionHtml: {
        fontSize: 13,
        width: 280,
        // [theme.breakpoints.up('md')]: {
        //     width: 850,
        //     height: '100%',
        // },
        // [theme.breakpoints.up('sm')]: {
        //     width: 700,
        //     height: '100%',
        // },
        [theme.breakpoints.down('sm')]: {
            width: 320,
            height: '100%',
            maxHeight: '100%',
        },
        '& img': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
        '& iframe': {
            width: '100%',
            height: '100%',
            [theme.breakpoints.up('md')]: {
                maxWidth: 800,
            },
            [theme.breakpoints.up('sm')]: {
                maxWidth: 650,
            },
            [theme.breakpoints.down('sm')]: {
                maxWidth: 300,
            },
        },
    },
    rowTab: {
        display: 'flex',
        fontSize: 13,
        color: '#1A1818',
        '& .tab-label': {
            width: '50%',
        },
    },

    listLabel: {
        ...FONT_REGULAR,
    },
    listValue: {
        ...FONT_DEFAULT,
        ...FONT_10,
    },
}));
