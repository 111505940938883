/* eslint-disable react/no-danger */
import React from 'react';
import Router from 'next/router';
import classNames from 'classnames';
import Product from '@core_modules/catalog/plugin/ProductList';
import { features } from '@config';
import CountdownTimer from '@common_countdown';
import useStyles from './style';

// sementara di comment dlu, untuk custom filter memakai aggregations product
// import { getFilter } from '../../../services/graphql';

const categoryTabs = (category) => {
    const data = [];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < category?.length; index++) {
        data.push(category[index]?.name);
    }
    return data;
};

const CategoryPage = ({
    data, storeConfig, t, BannerView, BreadcrumbView, TabView, dataEvent, ...other
}) => {
    const styles = useStyles();
    const [value] = React.useState(0);
    const categoryList = data?.categoryList[0];
    const eventProduct = dataEvent && dataEvent?.categoryList[0]?.event;
    const dataContent = {
        banner: [],
        onlyDescription: false,
        description: '',
    };
    const handleChange = (event, newValue) => {
        Router.push(
            '/[...slug]',
            `/${categoryList.children[newValue - 1].url_path}`,
        );
    };
    if (categoryList?.image_path) {
        dataContent.banner.push({
            imageUrl: categoryList?.image_path,
            link: categoryList?.url_path,
        });
        dataContent.description = categoryList?.description;
    } else {
        dataContent.onlyDescription = true;
        dataContent.description = categoryList?.description;
    }
    // sementara di comment dlu, untuk custom filter memakai aggregations product
    // const customFilter = getFilter(categoryList.id);
    let breadcrumbsData = [];
    if (categoryList?.breadcrumbs && categoryList?.breadcrumbs.length > 0) {
        breadcrumbsData = categoryList?.breadcrumbs.map((bc) => ({
            label: bc.category_name,
            link: `/${bc.category_url_path}`,
            active: false,
            id: bc.category_id,
        }));
    }
    breadcrumbsData.push({
        label: categoryList?.name,
        link: '#',
        active: true,
    });
    return (
        <>
            <style jsx>
                {`
                    .cms-block-category :global(img) {
                        width: 100%;
                        max-width: 100%;
                    }
                `}
            </style>
            <div className={styles.container}>
                {eventProduct && (
                    <CountdownTimer
                        targetDate={eventProduct.end_date}
                        t={t}
                        startDate={eventProduct.start_date}
                        status={eventProduct.status}
                    />
                )}
                <div className={classNames(styles.breadcrumbs, 'hidden-mobile')}>
                    <BreadcrumbView data={breadcrumbsData} />
                </div>
                {
                    ((dataContent.banner.length > 0) && !dataContent.onlyDescription)
                        ? (
                            <div className={styles.headContainer} style={{ width: '100%', height: 'auto' }}>
                                <BannerView
                                    src={dataContent.banner[0].imageUrl}
                                    width={features.imageSize.category.width}
                                    height={features.imageSize.category.height}
                                    showArrow={dataContent.banner.length > 1}
                                    style={{ width: '100%', height: 'auto' }}
                                />
                            </div>
                        ) : null
                }
                {/* <Typography variant="h1" className={styles.categoryName}>
                    {categoryList.name}
                </Typography> */}
                {dataContent.description && (
                    /* eslint-disable-next-line react/no-danger */
                    <div
                        className={classNames(styles.cmsContainer, dataContent.onlyDescription && styles.onlyDescription)}
                        dangerouslySetInnerHTML={{ __html: dataContent.description }}
                    />
                )}
                <div className="hidden-desktop">
                    <TabView
                        data={categoryTabs(categoryList?.children)}
                        onChange={handleChange}
                        value={value}
                    />
                </div>
                {
                    categoryList
                    && (categoryList?.display_mode === 'PRODUCTS_AND_PAGE' || categoryList?.display_mode === 'PAGE')
                    && categoryList?.cms_block
                    && (
                        <div className="cms-block-category" dangerouslySetInnerHTML={{ __html: categoryList?.cms_block.content }} />
                    )
                }
                {
                    categoryList
                    && (!categoryList?.display_mode
                        || categoryList?.display_mode === 'PRODUCTS_AND_PAGE'
                        || categoryList?.display_mode === 'PRODUCTS')
                    && (
                        <Product
                            // sementara di comment dlu, untuk custom filter memakai aggregations product
                            // customFilter={customFilter.loading ? [] : customFilter.data.getFilterAttributeOptions.data}
                            defaultSort={{ key: 'relevance', value: 'ASC' }}
                            catId={categoryList?.id}
                            categoryPath={categoryList?.url_path}
                            catalog_search_engine={storeConfig.catalog_search_engine}
                            t={t}
                            category={categoryTabs(categoryList?.children)}
                            dataTabs={categoryTabs(categoryList?.children)}
                            onChangeTabs={handleChange}
                            storeConfig={storeConfig}
                            isVirtualCategory={categoryList?.is_virtual_category}
                            {...other}
                        />
                    )
                }
            </div>
        </>
    );
};

export default CategoryPage;
