import React from 'react';
import Typography from '@common_typography';
import classNames from 'classnames';
import useStyles from './style';

const DateTimeDisplay = ({ value, type, isDanger }) => {
    const styles = useStyles();
    return (
        <div className={styles.timer}>
            <Typography
                className={classNames(isDanger ? styles.textDanger : styles.textBlack, styles.textTime)}
                variant="h2"
                type="bold"
                align="center"
            >
                {value < 10 ? `0${value}` : value}
            </Typography>
            <Typography className={classNames(isDanger ? styles.textDanger : styles.textBlack, styles.textDay)} variant="h6" align="center">
                {type}
            </Typography>
        </div>
    );
};

export default DateTimeDisplay;
