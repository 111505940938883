/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import Drawer from '@material-ui/core/Drawer';
import { modules } from '@config';
// import Router from 'next/router';
import React from 'react';
import ConfigurableOption from '@core_modules/product/plugin/OptionItem/ConfigurableOption';
import SimpleOption from '@core_modules/product/plugin/OptionItem/SimpleProduct';
import VirtualOption from '@core_modules/product/plugin/OptionItem/Virtual';
import DownloadOption from '@core_modules/product/plugin/OptionItem/Download';
import BundleOption from '@core_modules/product/plugin/OptionItem/BundleOption';
import GroupedOption from '@core_modules/product/plugin/OptionItem/GroupedProduct';
import CustomizableOption from '@core_modules/product/pages/default/components/CustomizableOption';
import useStyles from '@core_modules/product/pages/default/components/OptionItem/style';

const OptionDialog = (props) => {
    const {
        open,
        setOpen,
        data,
        price, customizableOptions, setCustomizableOptions,
        errorCustomizableOptions, additionalPrice, setAdditionalPrice,
        selectedColorSwatch, setSelectedColorSwatch, salableQtyChildProducts,
    } = props;
    const { __typename, max_qty_allowed } = data;
    const styles = useStyles();
    const [loading, setLoading] = React.useState(false);

    return (
        <>
            <Drawer
                anchor="bottom"
                open={open}
                onClose={setOpen}
                classes={{
                    paper: styles.popUpWrapper,
                }}
            >
                <div className={styles.root}>
                    {modules.product.customizableOptions.enabled && (
                        <CustomizableOption
                            {...data}
                            price={price}
                            customizableOptions={customizableOptions}
                            setCustomizableOptions={setCustomizableOptions}
                            errorCustomizableOptions={errorCustomizableOptions}
                            additionalPrice={additionalPrice}
                            setAdditionalPrice={setAdditionalPrice}
                        />
                    )}
                    {__typename === 'ConfigurableProduct' && (
                        <ConfigurableOption
                            {...props}
                            loading={loading}
                            setLoading={setLoading}
                            maxQtyAllowed={max_qty_allowed}
                            selectedColorSwatch={selectedColorSwatch}
                            setSelectedColorSwatch={setSelectedColorSwatch}
                            salableQtyChildProducts={salableQtyChildProducts}
                        />
                    )}

                    {__typename === 'BundleProduct' && (
                        <BundleOption
                            {...props}
                            loading={loading}
                            setLoading={setLoading}
                            maxQtyAllowed={max_qty_allowed}
                        />
                    )}

                    {__typename === 'SimpleProduct' && (
                        <SimpleOption
                            {...props}
                            loading={loading}
                            setLoading={setLoading}
                            maxQtyAllowed={max_qty_allowed}
                        />
                    )}

                    {__typename === 'VirtualProduct' && (
                        <VirtualOption
                            {...props}
                            loading={loading}
                            setLoading={setLoading}
                            maxQtyAllowed={max_qty_allowed}
                        />
                    )}

                    {__typename === 'DownloadableProduct' && (
                        <DownloadOption
                            {...props}
                            loading={loading}
                            setLoading={setLoading}
                            maxQtyAllowed={max_qty_allowed}
                        />
                    )}
                    {__typename === 'GroupedProduct' && (
                        <GroupedOption
                            {...props}
                            maxQtyAllowed={max_qty_allowed}
                        />
                    )}
                </div>
            </Drawer>
        </>
    );
};

export default OptionDialog;
