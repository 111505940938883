import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_PRIMARY, PRIMARY, WHITE, GRAY_SECONDARY,
} from '@theme_color';
import {
    CreateBorder, CreatePadding,
} from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        ...CreatePadding(0, 18, 0, 18),
        margin: '0 !important',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down(768)]: {
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.down(768)]: {
            ...CreatePadding(0, 0, 0, 0),
        },
        position: 'relative',
        '& .customizable-container': {
            marginTop: 20,
        },
    },
    headContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        position: 'relative',
        flex: '1 1 auto',
        maxWidth: '100%',
        [theme.breakpoints.down(768)]: {
            padding: '0',
        },
        [theme.breakpoints.down(1024)]: {
            flexWrap: 'wrap',
        },
        alignItems: 'flex-start',
    },
    productWrapper: {
        marginBottom: '18px',
        display: 'flex',
        flex: '1 1 100%',
        overflow: 'hidden',
        [theme.breakpoints.down(768)]: {
            padding: '0',
            flexWrap: 'wrap',
            marginBottom: '16px !important',
        },
        [theme.breakpoints.down(1024)]: {
            flex: '0 0 100%',
        },
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 3,
    },

    body: {
        ...CreatePadding(15, 30, 30, 30),
        display: 'flex',
        flexDirection: 'column',
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
    },

    bannerColumn: {
        background: '#fff',
        flex: '0 1 500px',
        overflow: 'hidden',
        padding: '18px 0 18px 18px',
        [theme.breakpoints.down(768)]: {
            flex: '0 0 100% !important',
            padding: '0 !important',
        },
        display: 'flex',
        flexWrap: 'noWrap',
    },
    bannerColumnLeft: {
        flex: '0 0 80px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '10px',
        '& > * + *': {
            marginTop: '10px',
        },
    },
    bannerColumnRight: {
        flex: '1 1 auto',
    },
    priceColumn: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 500px',
        padding: '18px',
        background: '#fff',
        [theme.breakpoints.down(768)]: {
            background: 'transparent !important',
            padding: '16px 16px 0 16px !important',
        },
    },

    qtyColumn: {
        maxWidth: '100%',
        overflow: 'hidden',
        marginLeft: '18px',
        flex: '0 0 360px',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: WHITE,
        padding: '18px',
        [theme.breakpoints.down(1024)]: {
            flex: '0 0 100% !important',
            marginLeft: '0 !important',
        },
        [theme.breakpoints.down(768)]: {
            padding: '16px !important',
        },
    },

    footer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-arround',
        position: 'fixed',
        bottom: 0,
        left: '50%',
        '-webkit-transform': 'translateX(-50%)',
        transform: ' translateX(-50%)',
        background: 'rgba(255,255,255,0.7)',
        // opacity : 0.7,
        padding: '16px',
        zIndex: theme.zIndex.drawer + 1,
    },

    title: {
        fontSize: '20px',
    },

    btnAddToCard: {
        width: '100%',
        height: 41,
        bottom: 0,
        left: 0,
        opacity: 'none',
        color: WHITE,
        borderRadius: 0,
        background: '#FF6A00',
        [theme.breakpoints.down(768)]: {
            width: '320px',
            maxWidth: '100%',
        },
    },

    textBtnAddToCard: {
        fontSize: 16,
        color: `${WHITE} !important`,
    },

    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '@media (max-width: 767px )': {
            '& .price-desktop': {
                display: 'none',
            },
        },
    },

    titleContainerQty: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    shareContainerQty: {
        marginTop: '18px',
        display: 'flex',
        flexDirection: 'column',
        '& > * + *': {
            marginTop: '18px',
        },
    },
    optionItem: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > * + *': {
            marginLeft: '18px',
        },
    },
    shareIcon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& > * + *': {
            marginLeft: '18px',
        },
    },
    titlePriceContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        fontSize: 20,
        '& > * + *': {
            marginTop: '18px',
        },
        '& .price_text': {
            fontSize: 30,
            color: '#313133',
        },
        '& .price_discount': {
            color: '#fff',
        },
        '& .price_old': {
            fontSize: 22,
            color: '#96999C',
        },
    },

    shareContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flex: 1,
        [theme.breakpoints.down(768)]: {
            '& button span': {
                fontSize: 9,
            },
            '& .MuiButton-outlined': {
                borderRadius: 8,
            },
            '& div': {
                textAlign: 'right',
            },
        },
    },

    shareRootContainer: {
        ...CreatePadding(15, 30, 30, 30),
        display: 'flex',
        flexDirection: 'column',
        ...CreateBorder(0, 0, '1px', 0, GRAY_PRIMARY),
        [theme.breakpoints.down(768)]: {
            marginBottom: 40,
            ...CreatePadding(0, 6, 20, 6),
        },
    },

    btnShare: {
        margin: '0px !important',
    },

    iconShare: {
        color: PRIMARY,
        fontSize: 25,
        margin: '0',
    },

    carouselContainer: {
        padding: '18px 0 0 0 !important',
        [theme.breakpoints.down(768)]: {
            padding: '16px 0 0 0 !important',
        },
    },
    desc: {
        margin: '12px 0 12px 0',
        textAlign: 'center',
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'row',
        // [theme.breakpoints.up(767)]: {
        //     marginBottom: 20,
        // },
        '& span': {
            textTransform: 'uppercase',
            fontSize: 13,
        },
    },
    btnLoadReview: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    textLoadReview: {
        color: `${GRAY_SECONDARY} !important`,
    },
    sku: {
        alignItems: 'center',
        marginLeft: '0 !important',
    },
    overviewTabs: {
        '& header': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderTop: '1px solid #96999C',
            borderBottom: '1px solid #96999C',
            // marginBottom: 10,
        },
        marginTop: 15,
        marginBottom: 15,
    },
    tabHeader: {
        marginBottom: '18px',
        [theme.breakpoints.down(1024)]: {
            marginTop: '18px !important',
        },
    },
    tabs: {
        background: '#fff',
        padding: '18px',
        [theme.breakpoints.down(1024)]: {
            marginTop: '18px !important',
        },
    },
    promoStyle: {
        '& span': {
            fontSize: 14,
            color: '#96999C',
            fontWeight: '600',
            margin: '0 !important',
        },
    },
    promoCard: {
        padding: '10px',
        width: '100%',
        border: '2px solid #DEDDDE',
        borderRadius: '4px',
        '& .title': {
            color: '#FF6A00',
            fontWeight: 'bold',
            fontSize: 13,
        },
        '& .content': {
            fontSize: 11,
        },
    },
    shareTitle: {
        marginTop: 20,
        fontSize: 12,
    },
    priceStyle: {
        '& div': {
            margin: 'unset',
        },
    },
    seeAllProduct: {
        display: 'flex',
        alignItems: 'center',
    },
    rightArrowStyle: {
        background: '#FF6A00',
        padding: 2,
        borderRadius: '100%',
        color: WHITE,
        display: 'flex',
    },
    textUppercase: {
        textTransform: 'uppercase',
    },
}));

export default useStyles;
