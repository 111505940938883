import Layout from '@layout';
import { StripHtmlTags } from '@helper_text';
import { getCategory, getCategoryEvent } from '../../services/graphql';
import generateSchemaOrg from '../../helpers/schema.org';

const Page = (props) => {
    const {
        Content, categoryId, storeConfig, SkeletonView, pageConfig = {}, ErrorView, ...other
    } = props;
    const { loading, data } = getCategory({
        productSize: 10,
        id: categoryId,
    });
    const { loading: loadingEvent, data: dataEvent } = getCategoryEvent({
        productSize: 10,
        id: categoryId,
    });
    const ogContent = {};
    let config = {};
    let schemaOrg = null;
    if (data && data.categoryList[0]) {
        const category = data.categoryList[0];
        schemaOrg = generateSchemaOrg(category, storeConfig);
        if (data.categoryList[0].description) {
            ogContent.description = StripHtmlTags(data.categoryList[0].description);
        }
        config = {
            title: loading ? '' : data.categoryList[0].meta_title,
            keywords: loading ? '' : data.categoryList[0].meta_keywords,
            description: loading ? '' : data.categoryList[0].meta_description,
            headerTitle: data && !data.categoryList[0].image_path ? data.categoryList[0].name : '',
            header: data && data.categoryList[0].image_path ? 'absolute' : 'relative', // available values: "absolute", "relative", false (default)
            bottomNav: 'browse',
            pageType: 'category',
            ogContent: {
                description: {
                    type: 'meta',
                    value: data.categoryList[0].meta_description || StripHtmlTags(data.categoryList[0].meta_description),
                },
                'og:description': data.categoryList[0].meta_description || StripHtmlTags(data.categoryList[0].meta_description),
            },
            schemaOrg,
        };
    }
    if (loadingEvent && loading && !data && !dataEvent) {
        return (
            <Layout {...props} pageConfig={config}>
                <SkeletonView />
            </Layout>
        );
    }

    if (data && !data.categoryList[0] && !dataEvent.categoryList[0]) {
        return <ErrorView statusCode={404} {...props} />;
    }
    return (
        <Layout {...props} pageConfig={config || pageConfig}>
            <Content
                categoryId={categoryId}
                storeConfig={storeConfig}
                data={data}
                dataEvent={dataEvent}
                {...other}
            />
        </Layout>
    );
};

export default Page;
