import Skeleton from '@material-ui/lab/Skeleton';
import useStyles from '../style';

const ProductItemSkeleton = () => {
    const styles = useStyles();
    return (
        <div className={styles.itemContainer} style={{ marginBottom: '18px' }}>
            <Skeleton variant="rect" width="100%" height="180px" style={{ marginBottom: '4px' }} />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
        </div>
    );
};

export default ProductItemSkeleton;
