/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import classNames from 'classnames';
import LeftArrowIcon from '@material-ui/icons/ArrowBackIos';
import RightArrowIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Slider from 'react-slick';
import ImageSlide from '@common_slick/Banner/ImageSlide';
import Thumbor from '@common_slick/Banner/Thumbor';
import useStyles from './style';

const Banner = ({
    data = [],
    height,
    width,
    // contentWidth = '',
    autoPlay = true,
    noLink = false,
    thumbnail = false,
    showArrow = true,
    speed = 500,
    autoplaySpeed = 4000,
    actionImage = () => { },
    children,
}) => {
    const styles = useStyles();
    const [slideIndex, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    let sliderRef = React.createRef();
    const handleLeftArrow = () => {
        sliderRef.slickGoTo(slideIndex - 1);
    };
    const handleRightArrow = () => {
        sliderRef.slickGoTo(slideIndex + 1);
    };
    function NextArrow(props) {
        const { onClick } = props;
        return (
            // <RightArrowIcon className={className} onClick={onClick} />
            <div className={classNames(styles.arrowGallery)} onClick={onClick}>
                <KeyboardArrowDown fontSize="inherit" />
            </div>
        );
    }

    const settings = {
        // className: thumbnail ? 'slick-thumbnail' : 'slick-pwa',
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: autoPlay,
        speed,
        autoplaySpeed,
        afterChange: () => setCount(count + 1),
        beforeChange: (current, next) => setIndex(next),
        arrows: false,
    };

    const settingsThumb = {
        vertical: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
    };

    return (
        <div className={styles.container}>
            {thumbnail ? (
                <div className={classNames(styles.thumbnailContainer, 'hidden-mobile')}>
                    <div className={styles.galleryThumb}>
                        {
                            data.length > 3 ? (
                                <Slider {...settingsThumb}>
                                    {data.map((item, id) => (
                                        <div
                                            className={slideIndex === id
                                                ? classNames(styles.thumbnail, styles.thumbnailActive)
                                                : classNames(styles.thumbnail)}
                                            key={id}
                                            onClick={() => {
                                                sliderRef.slickGoTo(id);
                                            }}
                                        >
                                            <Thumbor
                                                src={item.imageUrl}
                                                alt="thumbnail"
                                                width={100}
                                                height={100}
                                                quality={100}
                                                className={styles.thumbnailImg}
                                            />
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <>
                                    {data.map((item, id) => (
                                        <div
                                            className={slideIndex === id
                                                ? classNames(styles.thumbnail, styles.thumbnailActive)
                                                : classNames(styles.thumbnail)}
                                            key={id}
                                            onClick={() => {
                                                sliderRef.slickGoTo(id);
                                            }}
                                        >
                                            <Thumbor
                                                src={item.imageUrl}
                                                alt="thumbnail"
                                                width={100}
                                                height={100}
                                                quality={100}
                                                className={styles.thumbnailImg}
                                            />
                                        </div>
                                    ))}
                                </>
                            )
                        }
                    </div>
                </div>
            ) : null}
            <div className={classNames(styles.caraousel)}>
                <Slider ref={(slider) => sliderRef = slider} {...settings}>
                    {data.map((item, key) => (
                        <div onClick={actionImage} key={key}>
                            <ImageSlide
                                height={height}
                                customClass={styles.customClass}
                                width={width}
                                noLink={noLink}
                                priority={key === 0}
                                {...item}
                            />
                        </div>
                    ))}
                </Slider>
                {
                    showArrow ? (
                        <>
                            <div
                                className={thumbnail
                                    ? classNames(styles.arrow, styles.leftArrow, styles.leftArrowThumbnail)
                                    : classNames(styles.arrow, styles.leftArrow)}
                                onClick={handleLeftArrow}
                            >
                                <LeftArrowIcon fontSize="inherit" />
                            </div>
                            <div className={classNames(styles.arrow, styles.rightArrow)} onClick={handleRightArrow}>
                                <RightArrowIcon fontSize="inherit" />
                            </div>
                        </>
                    ) : null
                }
                {children}
            </div>
        </div>
    );
};
export default Banner;
