import React from 'react';
import Typography from '@common_typography';
import formatDate from '@helper_date';
import classNames from 'classnames';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';
import useStyles from './style';

const ExpiredNotice = ({ targetDate, startDate, status }) => {
    const styles = useStyles();
    return (
        <div className={styles.container}>
            <Typography className={styles.textDanger} variant="h4" align="center" letter="uppercase">
                {status}
            </Typography>
            <div className={styles.showCounter}>
                <Typography variant="h6" align="center">
                    {formatDate(startDate)}
                    {' '}
                    -
                    {' '}
                    {formatDate(targetDate)}
                </Typography>
            </div>
        </div>
    );
};

const ShowCounter = ({
    days, hours, minutes, t,
}) => {
    const styles = useStyles();
    return (
        <>
            <div className={styles.container}>
                <Typography className={classNames(styles.textBlack, styles.textTitle)} variant="h4" align="center" letter="uppercase">
                    {t('catalog:countdown:title')}
                </Typography>
                <div className={styles.showCounter}>
                    <DateTimeDisplay value={days} type={t('catalog:countdown:day')} isDanger={days <= 1} />
                    <DateTimeDisplay value={hours} type={t('catalog:countdown:hour')} isDanger={days <= 1 && hours <= 1} />
                    <DateTimeDisplay value={minutes} type={t('catalog:countdown:minute')} isDanger={days <= 1 && hours <= 1 && minutes <= 1} />
                </div>
            </div>
        </>
    );
};

const CountdownTimer = ({
    targetDate, t, startDate, status,
}) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
    const today = new Date();
    const date = `${today.getFullYear()}-${(`0${today.getMonth() + 1}`).slice(-2)}-${(`0${today.getDate()}`).slice(-2)}`;
    const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
    const dateTime = `${date} ${time}`;
    if (days + hours + minutes + seconds <= 0 || dateTime < startDate) {
        return <ExpiredNotice targetDate={targetDate} startDate={startDate} status={status} />;
    }
    return (
        <ShowCounter
            days={days}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            t={t}
        />
    );
};

export default CountdownTimer;
