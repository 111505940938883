import makeStyles from '@material-ui/core/styles/makeStyles';
import { FlexRow } from '@theme_mixins';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 20,
        margin: '0px 18px 0px 18px',
        background: '#fff',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: '0px 0px 0px 0px',
        },
    },
    showCounter: {
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        ...FlexRow,
    },
    timer: {
        margin: '0 50px',
        [theme.breakpoints.down('xs')]: {
            margin: '0 35px',
        },
        [theme.breakpoints.down(320)]: {
            margin: '0 15px',
        },
    },
    textDanger: {
        color: '#dc3545',
        fontFamily: 'museo-sans, sans-serif !important',
        fontStyle: 'normal',
    },
    textBlack: {
        fontFamily: 'museo-sans, sans-serif !important',
        fontStyle: 'normal',
        fontWeight: 300,
        color: '#333',
    },
    textTitle: {
        fontSize: '1.8rem',
        [theme.breakpoints.down(420)]: {
            fontSize: '1.5rem',
        },
    },
    textDay: {
        fontSize: '1.0rem',
    },
    textTime: {
        [theme.breakpoints.down(420)]: {
            fontSize: '1.9rem',
        },
    },
}));

export default useStyles;
