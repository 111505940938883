import Core from '@core_modules/product/plugin/OptionItem/ConfigurableOption/core';
import View from '@core_modules/product/plugin/OptionItem/ConfigurableOption/view';

const ConfigurableOptionItem = (props) => (
    <Core
        {...props}
        View={View}
    />
);

export default ConfigurableOptionItem;
