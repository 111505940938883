import Grid from '@material-ui/core/Grid';
import { transformAmoebaData } from '@root/src/helpers/transformAmoebaData';
import classNames from 'classnames';
import useStyles from './style';

const GridList = ({
    data = [],
    className = '',
    isAmoeba = false,
    bulkData = [],
    ItemComponent,
    itemProps = {},
    gridContainerProps = {},
    gridItemProps = {},
}) => {
    const styles = useStyles();
    const containerStyle = classNames(styles.container, className);
    if (!ItemComponent) return null;
    return (
        <Grid
            container
            spacing={1}
            className={containerStyle}
            {...gridContainerProps}
        >
            {data.map((item, index) => (
                <Grid item xs={6} key={index} {...gridItemProps} tdata="product-list">
                    <ItemComponent
                        {...itemProps}
                        {...transformAmoebaData(item, isAmoeba)}
                        isAmoeba={isAmoeba}
                        bulkData={bulkData}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default GridList;
