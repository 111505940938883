import React, { useEffect, useMemo } from 'react';
import GridList from '@common_gridlist';
import Typography from '@common_typography';
import classNames from 'classnames';
import { modules } from '@config';
import Pagination from '@material-ui/lab/Pagination';
import Filter from './Filter';
import FilterDesktop from './FilterDesktop';
import ProductItem from '../../ProductItem/index';
import LabelView from '../../ProductItem/components/LabelView';
import useStyles from './style';
import Sort from './FilterDesktop/sort';

const ProdItem = (props) => (
    <ProductItem
        {...props}
    />
);

const productLenghtAmoeba = (isAmoeba, products) => (isAmoeba ? products.products.length < products.total_product
    : products.items.length < products.total_count);

/**
 * For Content Pagination
 * @param {*} props
 * @returns
 */
const ContentPagination = (props) => {
    const {
        bulkData = [], query, showTabs, customFilter, elastic, t,
        aggregations, setFiltervalue, category, defaultSort, config, TabView,
        products, categoryPath, renderEmptyMessage, ProductListSkeleton, loading,
        loadmore, dataTabs, onChangeTabs, isAmoeba = false,
        page, totalCount, totalPage, handleChangePage, ...other
    } = props;
    const styles = useStyles();

    const PaginationInfo = () => {
        if (loading || loadmore || totalCount > 0) {
            return (
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <Pagination
                        count={totalPage}
                        page={page}
                        onChange={(e, p) => handleChangePage(p)}
                        showFirstButton
                        showLastButton
                        siblingCount={1}
                        boundaryCount={1}
                    />
                </div>
            );
        }

        return <></>;
    };

    /**
     * LoadMore Section when pagination is true
     * @returns
     */
    const PaginationSection = () => (
        <>
            {loadmore && <ProductListSkeleton />}
            {!loadmore && (
                <GridList
                    data={isAmoeba ? products.products : products.items}
                    isAmoeba={isAmoeba}
                    bulkData={bulkData}
                    ItemComponent={ProdItem}
                    className="grid"
                    itemProps={{
                        categorySelect: categoryPath,
                        LabelView,
                        className: 'grid-item',
                        ...other,
                    }}
                    gridItemProps={{ xs: 6, sm: 4, md: modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 3 : 3 }}
                />
            )}
            <PaginationInfo />
        </>
    );

    /**
     * using useMemo to prevent re-render list
     * @returns
     */
    const MainListSection = useMemo(() => <PaginationSection />, [products, bulkData]);

    return (
        <>
            <div className={styles.containerProduct}>
                {showTabs ? (
                    <div className="hidden-desktop">
                        <TabView
                            // eslint-disable-next-line radix
                            value={query.category_id ? query.category_id : 0}
                            data={category}
                            onChange={(e, value) => setFiltervalue({ ...query, ...{ category_id: value } })}
                            {...other}
                        />
                    </div>
                ) : null}
                <div className={modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 'hidden-desktop' : ''}>
                    <Filter
                        filter={customFilter || aggregations}
                        defaultSort={JSON.stringify(defaultSort)}
                        filterValue={query}
                        setFiltervalue={setFiltervalue}
                        isSearch={!!config.search}
                        products={products}
                        isAmoeba={isAmoeba}
                        renderEmptyMessage={renderEmptyMessage}
                        loading={loading}
                        t={t}
                        {...other}
                    />
                </div>
                {modules.catalog.productListing.drawerFilterOnDesktop.enabled ? (
                    <div className={classNames(styles.filterBtnContainer, 'hidden-mobile')}>
                        <Sort
                            filter={customFilter || aggregations}
                            defaultSort={JSON.stringify(defaultSort)}
                            filterValue={query}
                            setFiltervalue={setFiltervalue}
                            isSearch={!!config.search}
                            t={t}
                            {...other}
                        />
                    </div>
                ) : null}

                <div className="row">
                    {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                        ? (
                            <div className="col-sm-12 col-lg-2 hidden-mobile">
                                <FilterDesktop
                                    filter={customFilter || aggregations}
                                    defaultSort={JSON.stringify(defaultSort)}
                                    filterValue={query}
                                    setFiltervalue={setFiltervalue}
                                    isSearch={!!config.search}
                                    products={products}
                                    isAmoeba={isAmoeba}
                                    renderEmptyMessage={renderEmptyMessage}
                                    loading={loading}
                                    tabs={dataTabs}
                                    t={t}
                                    onChangeTabs={onChangeTabs}
                                />
                            </div>
                        )
                        : null}
                    <div className={`col-sm-12 col-xs-12 col-lg-${modules.catalog.productListing.drawerFilterOnDesktop.enabled ? '10' : '12'}`}>
                        {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                            ? (
                                <Typography variant="p" type="regular" className={classNames('hidden-mobile', styles.countProductTextDesktop)}>
                                    {totalCount}
                                    {' '}
                                    {t('catalog:product:name')}
                                </Typography>
                            ) : null}
                        <div className={styles.productContainer}>
                            {/** Pagination List */}
                            {MainListSection}
                            {/** Pagination List */}
                            {
                                isAmoeba ? (
                                    <>
                                        {
                                            (products.products.length === products.total_product) || loading
                                                ? renderEmptyMessage(products.products.length, loading)
                                                : null
                                        }
                                    </>
                                ) : (
                                    <>
                                        {(products.items.length === products.total_count) || loading
                                            ? renderEmptyMessage(products.items.length, loading)
                                            : null}
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * For Content Loadmore
 * @param {*} props
 * @returns
 */
const ContentLoadMore = (props) => {
    const {
        bulkData = [], query, showTabs, customFilter, elastic, t,
        aggregations, setFiltervalue, category, defaultSort, config, TabView,
        products, categoryPath, renderEmptyMessage, ProductListSkeleton, loading,
        loadmore, handleLoadMore, dataTabs, onChangeTabs, isAmoeba = false, ...other
    } = props;
    const styles = useStyles();

    const handleScroll = () => {
        // const lastUserLoaded = document.querySelector(`.grid-item:last-child`);
        const lastUserLoaded = document.querySelector('.latest-product-indicator');
        if (lastUserLoaded) {
            const lastUserLoadedOffset = lastUserLoaded.offsetTop + lastUserLoaded.clientHeight;
            const pageOffset = window.pageYOffset + window.innerHeight;
            if (pageOffset > lastUserLoadedOffset && !loadmore && productLenghtAmoeba(isAmoeba, products)) {
                handleLoadMore();
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <>
            <div className={styles.containerProduct}>
                {showTabs ? (
                    <div className="hidden-desktop">
                        <TabView
                            // eslint-disable-next-line radix
                            value={query.category_id ? query.category_id : 0}
                            data={category}
                            onChange={(e, value) => setFiltervalue({ ...query, ...{ category_id: value } })}
                            {...other}
                        />
                    </div>
                ) : null}
                <div className={modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 'hidden-desktop' : ''}>
                    <Filter
                        filter={customFilter || aggregations}
                        defaultSort={JSON.stringify(defaultSort)}
                        filterValue={query}
                        setFiltervalue={setFiltervalue}
                        isSearch={!!config.search}
                        products={products}
                        isAmoeba={isAmoeba}
                        renderEmptyMessage={renderEmptyMessage}
                        loading={loading}
                        t={t}
                        {...other}
                    />
                </div>
                {modules.catalog.productListing.drawerFilterOnDesktop.enabled ? (
                    <div className={classNames(styles.filterBtnContainer, 'hidden-mobile')}>
                        <Sort
                            filter={customFilter || aggregations}
                            defaultSort={JSON.stringify(defaultSort)}
                            filterValue={query}
                            setFiltervalue={setFiltervalue}
                            isSearch={!!config.search}
                            t={t}
                            {...other}
                        />
                    </div>
                ) : null}

                <div className="row">
                    {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                        ? (
                            <div className="col-sm-12 col-lg-2 hidden-mobile">
                                <FilterDesktop
                                    filter={customFilter || aggregations}
                                    defaultSort={JSON.stringify(defaultSort)}
                                    filterValue={query}
                                    setFiltervalue={setFiltervalue}
                                    isSearch={!!config.search}
                                    products={products}
                                    isAmoeba={isAmoeba}
                                    renderEmptyMessage={renderEmptyMessage}
                                    loading={loading}
                                    tabs={dataTabs}
                                    t={t}
                                    onChangeTabs={onChangeTabs}
                                />
                            </div>
                        )
                        : null}
                    <div className={`col-sm-12 col-xs-12 col-lg-${modules.catalog.productListing.drawerFilterOnDesktop.enabled ? '10' : '12'}`}>
                        {modules.catalog.productListing.drawerFilterOnDesktop.enabled
                            ? (
                                <Typography variant="p" type="regular" className={classNames('hidden-mobile', styles.countProductTextDesktop)}>
                                    {isAmoeba ? products.total_product : products.total_count}
                                    {' '}
                                    {t('catalog:product:name')}
                                </Typography>
                            ) : null}
                        <div className={styles.productContainer}>
                            {loading && <ProductListSkeleton />}
                            {!loading && (
                                <GridList
                                    data={isAmoeba ? products.products : products.items}
                                    isAmoeba={isAmoeba}
                                    bulkData={bulkData}
                                    ItemComponent={ProdItem}
                                    className="grid"
                                    itemProps={{
                                        categorySelect: categoryPath,
                                        LabelView,
                                        className: 'grid-item',
                                        ...other,
                                    }}
                                    gridItemProps={{ xs: 6, sm: 4, md: modules.catalog.productListing.drawerFilterOnDesktop.enabled ? 3 : 3 }}
                                />
                            )}
                            <div className="latest-product-indicator" />
                            {
                                isAmoeba ? (
                                    <>
                                        {
                                            (products.products.length === products.total_product) || loading
                                                ? renderEmptyMessage(products.products.length, loading)
                                                : null
                                        }
                                    </>
                                ) : (
                                    <>
                                        {(products.items.length === products.total_count) || loading
                                            ? renderEmptyMessage(products.items.length, loading)
                                            : null}
                                    </>
                                )
                            }
                            {loadmore ? (
                                <div className={styles.divLoadMore}>
                                    <Typography align="center" variant="span" type="bold" letter="uppercase" color="gray">
                                        Loading
                                    </Typography>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * Main Content
 * @param {*} props
 * @returns
 */
const Content = (props) => {
    const { isPagination } = props;
    const MainContent = isPagination ? ContentPagination : ContentLoadMore;

    return <MainContent {...props} />;
};

export default Content;
