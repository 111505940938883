/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-mixed-operators */
import Skeleton from '@common_skeleton';
import CarouselSkeleton from '@common_slick/Caraousel/Skeleton';
import classNames from 'classnames';
import useStyles from './style';

const SkeletonLoader = () => {
    const styles = useStyles();
    return (
        <div className={classNames(styles.container)}>
            <div className="hidden-mobile" style={{ paddingTop: '18px', marginBottom: '18px' }}>
                <Skeleton
                    variant="rect"
                    width={240}
                    animation="wave"
                />
            </div>
            <div className={classNames(styles.headContainer)}>
                <div className={styles.productWrapper}>
                    <div className={styles.bannerColumn}>
                        <div className={classNames(styles.bannerColumnLeft, 'hidden-mobile')}>
                            {
                                [1, 2, 3].map((val) => {
                                    return (
                                        <Skeleton
                                            key={val}
                                            variant="rect"
                                            width={80}
                                            height={80}
                                            animation="wave"
                                        />
                                    );
                                })
                            }
                            <Skeleton
                                variant="rect"
                                width={80}
                                height={20}
                                animation="wave"
                            />
                        </div>
                        <div className={styles.bannerColumnRight}>
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height={420}
                                animation="wave"
                            />
                        </div>
                    </div>
                    <div className={classNames(styles.priceColumn)}>
                        <div className={classNames(styles.titleContainer, 'hidden-desktop')}>
                            <Skeleton
                                variant="rect"
                                width="65%"
                                height={20}
                                animation="wave"
                            />
                        </div>
                        <div className={classNames(styles.titleContainer, 'hidden-mobile')}>
                            <div className={classNames(styles.titlePriceContainer, 'price-desktop')}>
                                <Skeleton
                                    variant="rect"
                                    width="65%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="40%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="55%"
                                    height={40}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="45%"
                                    height={30}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={160}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="40%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={60}
                                    animation="wave"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(styles.qtyColumn)}>
                    <div className={classNames(styles.titleContainerQty, 'hidden-mobile')}>
                        <Skeleton
                            variant="rect"
                            width="40%"
                            height={20}
                            animation="wave"
                        />
                    </div>
                    <div className="hidden-desktop">
                        <div className={classNames(styles.titleContainer)}>
                            <div className={classNames(styles.titlePriceContainer)}>
                                <Skeleton
                                    variant="rect"
                                    width="60%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="40%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="55%"
                                    height={40}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="45%"
                                    height={30}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={160}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="40%"
                                    height={20}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={60}
                                    animation="wave"
                                />
                                <div className={styles.shareIcon}>
                                    <Skeleton
                                        variant="rect"
                                        width="30%"
                                        height={30}
                                        animation="wave"
                                    />
                                    <Skeleton
                                        variant="rect"
                                        width="30%"
                                        height={30}
                                        animation="wave"
                                    />
                                </div>
                                {
                                    [1, 2, 3].map((val) => {
                                        return (
                                            <Skeleton
                                                key={val}
                                                variant="rect"
                                                width="100%"
                                                height={30}
                                                animation="wave"
                                            />
                                        );
                                    })
                                }
                                <Skeleton
                                    variant="rect"
                                    width="100%"
                                    height={120}
                                    animation="wave"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="hidden-mobile">
                        <div className={styles.shareContainerQty}>
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height={40}
                                animation="wave"
                            />
                            <Skeleton
                                variant="rect"
                                width="20%"
                                height={20}
                                animation="wave"
                            />
                            <div className={styles.optionItem}>
                                {
                                    [1, 2, 3, 4].map((val) => {
                                        return (
                                            <Skeleton
                                                key={val}
                                                animation="wave"
                                                variant="circle"
                                                width={30}
                                                height={30}
                                            />
                                        );
                                    })
                                }
                            </div>
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height={40}
                                animation="wave"
                            />
                            <Skeleton
                                variant="rect"
                                width="100%"
                                height={40}
                                animation="wave"
                            />
                            <div className={styles.shareIcon}>
                                <Skeleton
                                    variant="rect"
                                    width="30%"
                                    height={30}
                                    animation="wave"
                                />
                                <Skeleton
                                    variant="rect"
                                    width="30%"
                                    height={30}
                                    animation="wave"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.tabHeader, 'hidden-mobile')}>
                <Skeleton
                    variant="rect"
                    width="60%"
                    height={30}
                    animation="wave"
                />
            </div>
            <div className={classNames(styles.tabs, 'hidden-mobile')}>
                <Skeleton
                    variant="rect"
                    width="100%"
                    height={240}
                    animation="wave"
                />
            </div>
            <div className={classNames(styles.carouselContainer)}>
                <div className="pwa-product-list">
                    <div className="pwa-subsection-header">
                        <div className="pwa-subsection-title-container">
                            <span className={classNames('pwa-subsection-title avenir-black')}>
                                <Skeleton
                                    variant="rect"
                                    width={120}
                                    animation="wave"
                                />
                            </span>
                        </div>
                    </div>
                    <div className="pwa-product-slider-container">
                        <div className="pwa-product-slider-full">
                            <CarouselSkeleton />
                        </div>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.carouselContainer)}>
                <div className="pwa-product-list">
                    <div className="pwa-subsection-header">
                        <div className="pwa-subsection-title-container">
                            <div className={classNames('pwa-subsection-title avenir-black')}>
                                <Skeleton
                                    variant="rect"
                                    width={120}
                                    animation="wave"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="pwa-product-slider-container">
                        <div className="pwa-product-slider-full">
                            <CarouselSkeleton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SkeletonLoader;
