import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_SECONDARY } from '@theme_color';

export default makeStyles((theme) => ({
    container: {
        position: 'relative',
        padding: '116px 20px 50px 20px',
        maxHeight: 900,
        [theme.breakpoints.up('sm')]: {
            padding: '50px 20px 50px 20px',
        },
    },
    buttonClose: {
        position: 'absolute',
        top: 20,
        right: 20,
        color: GRAY_SECONDARY,
        fontSize: '2rem',
        zIndex: theme.zIndex.drawer + 9,
        [theme.breakpoints.up('sm')]: {
            fontSize: '3rem',
        },
    },
}));
