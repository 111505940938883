import makeStyles from '@material-ui/core/styles/makeStyles';
import { GRAY_PRIMARY, PRIMARY } from '@theme_color';

export default makeStyles(() => ({
    container: {
        flexDirection: 'row',
    },
    iconBtn: {
        marginRight: 0,
        padding: 0,
    },
    icon: {
        color: GRAY_PRIMARY,
        '&:hover': {
            color: `${PRIMARY} !important`,
        },
    },
    iconActive: {
        color: '#FF6A00',
    },
    textStar: {
        fontSize: '10px',
        textTransform: 'uppercase',
    },
}));
